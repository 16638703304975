@import "../../css/global.scss";

.button {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: Sequel100Black-55;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: center;
  height: 41px;
  padding: 1rem;
  text-align: center;
  transition: 0.3s all;
  width: fit-content;

  position: relative;
  z-index: 1;

  &:disabled {
    background: $glass-disabled;
    border: unset;
    color: $neutral-gray;
    cursor: not-allowed;

    svg {
      path {
        fill: $neutral-gray;
      }
    }

    &:hover {
      border: unset;
    }
  }

  img {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.fullWidth {
  display: flex;
  justify-content: center;
  width: 100%;
}

.compact {
  align-items: center;
  display: flex;
  font-size: 16px;
  height: fit-content;
  padding: 0.5rem 1rem;
  width: fit-content;
}

.leftIcon {
  align-items: center;
  display: flex;
  margin-right: 0.5rem;
}

.rightIcon {
  align-items: center;
  display: flex;
  margin-left: 0.5rem;
}

.gray {
  background: $grays-card-background;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid transparent;
  backdrop-filter: blur(12.5px);
  box-sizing: border-box;
  border-radius: 0.25rem;;
  text-shadow: 0px 0px 50px 4px rgba(67, 186, 125, 0.5);

  &:hover {
    border: 1px solid $glass-outside-stroke;
  }
}

.blue {
  border: 1px solid $secondary-blue;
  border-radius: $s;
  @include secondary-blue-background;
  color: $neutral-black;
  padding: $m $xxl;
  text-decoration: none;

  @include header-buttons;

  svg {
    path {
      fill: $neutral-black;
      transition: 0.1s all;
    }
  }

  &:hover {
    border: 1px solid $secondary-blue;
    background-color: $secondary-blue-30;
    color: $white;
    box-sizing: border-box;
    transition: 0.1s all;

    svg {
      path {
        fill: $secondary-blue;
        transition: 0.1s all;
      }
    }
  }

  &:disabled {
    &:hover {
      background: $glass-disabled;
      border: unset;
      color: $neutral-gray;
      cursor: not-allowed;
    }
  }
}

.red {
  background: $system-red-red;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid transparent;
  backdrop-filter: blur(12.5px);
  box-sizing: border-box;
  border-radius: 8px;
  height: 41px;
  text-shadow: 0px 0px 50px 4px rgba(67, 186, 125, 0.5);

  &:hover {
    border: 1px solid $glass-outside-stroke;
  }
}

.blueModule {
  @include secondary-blue-background;
  border: 1px solid transparent;
  border-radius: unset;
  color: $neutral-black;
  height: unset;
  padding: 1.5rem;
  width: 100%;

  svg {
    path {
      fill: $neutral-black;
    }
  }

  &:hover {
    border: 1px solid var(--primary-blue, rgba(0, 0, 0, 0.35));
    background: linear-gradient(
        0deg,
        rgba(132, 196, 255, 0.25) 0%,
        rgba(132, 196, 255, 0.25) 100%
      ),
      linear-gradient(
        137deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.07) 100%
      ),
      rgba(0, 0, 0, 0.35);
    background-blend-mode: color-dodge, normal, normal;

    .textContent {
      @include primary-light-blue-text;
    }

    svg {
      path {
        fill: #84c4ff;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid transparent;
      @include primary-light-blue-background;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      z-index: 1;
      transition: 0.3s all;
      pointer-events: none;
    }
  }

  &:disabled {
    background: $neutral-dark-gray;
    color: $neutral-gray;
    &:hover {
      .textContent {
        background: $neutral-gray;
        color: $neutral-gray;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      svg {
        path {
          fill: $neutral-gray;
        }
      }
      &:before {
        color: $neutral-gray;
        background: unset;
        border: unset;
      }
    }
  }
}

.grayModule {
  background: $glass-background-hover;
  backdrop-filter: blur(12.5px);
  border: 1px solid transparent;
  border-radius: unset;
  color: $white;
  height: unset;
  padding: 1.5rem;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    background: $neutral-white-alpha-30;
    border: 1px solid $neutral-white-alpha-30;
  }
}

.beige {
  border: 1px solid $primary-beige;
  background: $primary-beige;
  color: $neutral-black;
  font-family: Sequel100Black-85;
  @include header-buttons;

  &:hover {
    background: $primary-yellow;
  }

  &:disabled {
    &:hover {
      background: $glass-disabled;
    }
  }
}

.beigeLight {
  border: 1px solid $primary-beige;
  background: $primary-beige;
  color: $neutral-black;
  @include body-s-bold;
}

.beigeOutline {
  border: 1px solid $primary-beige;
  background: $neutral-white-alpha-15;
  border-radius: 0.25rem;
  backdrop-filter: blur(12.5px);
  color: $primary-beige;
  transition: 0.3s ease-in all;
  @include body-s-bold;
  text-transform: uppercase;

  svg {
    path {
      transition: 0.3s ease-in all;
    }
  }

  &:hover {
    background-color: $primary-beige;
    color: $neutral-black;

    svg {
      path {
        fill: $neutral-black;
      }
    }
  }
}

.beigeModule {
  background: $primary-beige;
  backdrop-filter: blur(12.5px);
  border: 1px solid transparent;
  border-radius: 0 0 1rem 1rem;
  color: $neutral-black;
  height: unset;
  padding: 1.5rem;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    background: $primary-yellow;
  }

  &:disabled {
    &:hover {
      background: $glass-disabled;
    }
  }
}

.yellow {
  background: var(--brand-yellow, #FFE400);
  color: $neutral-black;
}

.transparent {
  background: $brand-blue-alpha-15;
  backdrop-filter: blur(25px);
  border-radius: 0.5rem;
  border: unset;
  height: 3.25rem;
  @include header-xs;
  color: $brand-blue;
  text-wrap: nowrap;

  svg {
    width: 1.125rem;
    height: 1.125rem;
    path {
      fill: $brand-blue;
    }
  }

  @media (max-width: 960px) {
    @include header-buttons;
  }

  &:hover {
    background-color: $brand-dark-blue;
  }
}

.outline {
  border: 1px solid $white;
}

.rounded {
  border-radius: 40px;

  &::before {
    border-radius: 40px !important;
  }
}

.buttonGroup {
  width: 50%;
}
