@import "../../css/global.scss";

.cardDateTag {
  backdrop-filter: blur(50px);
  border-radius: 0px;
  backdrop-filter: blur(50px);
  border-right: 0.5px solid $neutral-white-alpha-15;
  border-radius: 8px;
  color: $primary-beige;
  height: fit-content;
  @include body-xs-bold;
  padding-right: 1rem;
  text-shadow: 0px 0px 50px rgba(67, 186, 125, 0.5);
  max-width: fit-content;
}

.month {
  text-align: left;
  @include sequel-header-xs;
}

.day {
  color: $primary-beige;
  @include sequel-header-xl;
  text-align: left;
  width: 100%;
}

.dayName {
  color: $neutral-white-alpha-50;
  @include rm-all-caps-xs;
  text-align: left;
  text-transform: uppercase;
  text-wrap: nowrap;
}
