@import "../css/global.scss";

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include glass-background;
    max-width: calc(1100px - 10rem);
    margin: 0 auto 5rem auto;
    padding: 10rem 5rem;

    h1 {
        color: $primary-beige;
        @include header-l;
        margin-bottom: 3rem;
        text-transform: uppercase;
    }

    a {
        text-decoration: none !important;
    }
}