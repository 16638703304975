@import "../css/global.scss";

.container {
  align-items: center;
  border-radius: 16px;
  color: $primary-beige;
  display: flex;
  @include body-s;
  flex-direction: column;
  justify-content: center;
  margin: 2rem auto;
  padding: 2rem 1rem;

  @media (max-width: 960px) {
    padding: 0;
    width: calc(100% - 2rem);
  }
}

.iconContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  display: flex;
  justify-content: center;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  width: 64px;
}

.new {
  margin: 0 auto;
  text-align: center;

  h2 {
    color: $primary-beige;
    @include header-s;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;

    &:first-of-type {
      margin-top: 1rem;
    }

    @media (max-width: 960px) {
      @include header-xs;
    }
  }
  p {
    margin-top: 0.5rem;
    @include body-s-light;
    margin-bottom: 1.5rem;
  }
}
