@import '../../css/global.scss';

.videoContainer {
    @media (max-width: 960px) {
        height: 75vh;

        video {
            margin: auto 0;
            height: 100%;
            max-width: 91vw;
      }
    }
}

.blackBackground {
    background-color: #101010;
    background-image: unset;

    :global(#backgroundImage) {
        display: none;
    }

    @media (max-width: 960px) {
        margin-top: 3rem !important;
    }
}

.hideVideo {
    display: none;
}

.contentContainer {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    height: fit-content;

    @media (max-width: 960px) {
        flex-direction: column;
        padding-top: 0;
    }
}

.imageContainer {
    border-radius: 8px;

    @include stroke-border;
    margin: 1rem;
    width: 24rem;
    height: 24rem;

    @media (max-width: 960px) {
        width: 50%;
        height: 100%;
        margin: 1rem auto 0 auto;
    }
}

.image {
    display: block !important;
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.content {
    background: $neutral-black;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    @include stroke-border;
    backdrop-filter: blur(12.5px);
    height: fit-content;
    margin: 1rem;
    padding: 2rem 1rem;
    width: 24rem;

    a {
        text-decoration: none;
    }

    @media (max-width: 960px) {
        width: calc(100% - 4rem);
        height: 100%;
        margin-top: 1rem;
    }


    .green {
        color: $system-green-green;
        @include header-m;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: 960px) {
            @include header-s;
        }
    }

    .rewardContainer {
        @include glass-background;
        @include stroke-border;
        padding: 1rem;

        h1 {
            color: $primary-beige;
            @include header-xl;
            margin: 0;
            text-transform: uppercase;
            text-align: center;

            @media (max-width: 960px) {
                @include header-l;
            }
        }

        p {
            color: $primary-beige;
            @include body-s;
            margin-bottom: 0;
            text-align: center;

            @media (max-width: 960px) {
                margin-top: 0;
            }
        }
    }

    .rewardDescription {
        @include body-s;
        color: $neutral-gray;
        margin: 2rem auto;

        @media (max-width: 960px) {
            margin: 1rem auto;
        }
    }
}
