.main,
#root {
  background-color: black;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

// ---------------- BREAKPOINTS ----------------

$breakpoint-xs: 320px;
$breakpoint-s: 480px;
$breakpoint-m: 768px;
$breakpoint-l: 960px;
$breakpoint-xl: 1100px;
$breakpoint-xxl: 1400px;

// --------------- SIZE -----------------------
$xxl: 32px;

$m: 12px;
$s: 8px;
$xs: 4px;

// --------------- FONTS -----------------------

@font-face {
  font-family: JKR Haas Grotesk Bold;
  src: url("./fonts/JKR-Haas-Grotesk-Knowles.otf") format("opentype");
}

@font-face {
  font-family: JKR Haas Grotesk;
  src: url("./fonts/JKR-Haas-Grotesk-Ritchie.otf") format("opentype");
}

@font-face {
  font-family: Digital-7 Mono;
  src: url("./fonts/digital-7-mono.ttf") format("truetype");
}

@font-face {
  font-family: Gobold;
  src: url("./fonts/Gobold-Regular.woff");
}

@font-face {
  font-family: Gobold Bold;
  src: url("./fonts/Gobold-Bold.woff");
}

@font-face {
  font-family: Sequel100Black-85;
  src: url("./fonts/Sequel100Black-85.ttf");
}

@font-face {
  font-family: Sequel100Black-75;
  src: url("./fonts/Sequel100Black-75.ttf");
}

@font-face {
  font-family: Sequel100Black-65;
  src: url("./fonts/Sequel100Black-65.ttf");
}

@font-face {
  font-family: Sequel100Black-55;
  src: url("./fonts/Sequel100Black-55.ttf");
}

@font-face {
  font-family: Roboto Mono;
  src: url("./fonts/RobotoMono-VariableFont_wght.ttf");
}

// --------------- COLORS -----------------------

$ticketdex-gradient: linear-gradient(
  60deg,
  rgb(184, 144, 255) 0%,
  rgb(120, 208, 255) 97%,
  rgb(117, 212, 255) 100%
);

// grayscale
$pure-white: #fff;
$pure-black: #000;
$white: #fafafa;
$gray-secondary: #8d8d8d;
$ticketdex-purple: #e18df4;
$ticketdex-green: #43ba7d;

$gray-400: #bdbdbd;

$grays-mains-gray: $gray-secondary;
$grays-dark-gray: #3b3d41;

// xp colors

$primary-navy: #18056c;
$primary-beige: #fdfcea;
$primary-yellow: #ffe400;

$primary-yellow-alpha-15: rgba(255, 228, 0, 0.15);
$primary-yellow-solid-15: #262200;

$brand-blue: #85d5ff;
$brand-blue-alpha-15: rgba(133, 213, 255, 0.15);
$brand-dark-blue: #001631;
$brand-dark-blue-alpha-15: rgba(0, 22, 49, 0.15);
$brand-darker-blue: #050e1f;

$primary-light-blue-gradient: linear-gradient(0deg, #84c4ff 0%, #84c4ff 100%),
  linear-gradient(
    137deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.07) 100%
  ),
  rgba(0, 0, 0, 0.35);

@mixin primary-light-blue-background {
  background: $primary-light-blue-gradient;
  background-blend-mode: color-dodge, normal, normal;
}

@mixin primary-light-blue-text {
  @include primary-light-blue-background;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

$secondary-blue: #19b2e6;

@mixin secondary-blue-background {
  background: linear-gradient(0deg, #84c4ff 0%, #84c4ff 100%),
    linear-gradient(
      137deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.07) 100%
    ),
    rgba(0, 0, 0, 0.35);
}

$secondary-blue-30: rgba(25, 178, 230, 0.3);
$secondary-gray-white: #e5e6ff;
$secondary-pink: #db4b81;

$neutral-black: #181a1e;
$white: #fafafa;
$neutral-gray: #b8b8b8;
$neutral-dark-gray: #3b3d41;
$neutral-input-box: #292929;
$ticketdex-non-opaque-background: #1d1b22;

$grays-card-background: $neutral-input-box;

$neutral-white-alpha-50: rgba(250, 250, 250, 0.5);
$neutral-white-alpha-30: rgba(250, 250, 250, 0.3);
$neutral-white-alpha-15: rgba(250, 250, 250, 0.15);

$neutral-black-alpha-75: rgba(24, 26, 30, 0.75);
$neutral-black-alpha-50: rgba(24, 26, 30, 0.5);
$neutral-black-alpha-30: rgba(24, 26, 30, 0.3);
$neutral-black-alpha-15: rgba(24, 26, 30, 0.15);

$system-green-green: #19b963;
$system-yellow-yellow: #f90;
$system-red-red: #d41503;

$system-green-alpha-50: rgba(25, 185, 99, 0.5);
$system-green-alpha-30: rgba(25, 185, 99, 0.3);
$system-green-alpha-15: rgba(25, 185, 99, 0.15);

$system-yellow-alpha-50: rgba(255, 153, 0, 0.5);
$system-yellow-alpha-30: rgba(255, 153, 0, 0.3);
$system-yellow-alpha-15: rgba(255, 153, 0, 0.15);

$system-red-alpha-50: rgba(212, 21, 3, 0.5);
$system-red-alpha-30: rgba(212, 21, 3, 0.3);
$system-red-alpha-15: rgba(212, 21, 3, 0.15);

$spotify-green: #1ed760;
$apple-music-red: #e85e6c;
$usdc-blue: #2775ca;
$discord-blurple: #5865f2;
$twitter-blue: #55acee;

// --------------- GLASS -----------------------

$glass-background: linear-gradient(
    137deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.07) 100%
  ),
  rgba(0, 0, 0, 0.35);

$glass-background-hover: linear-gradient(
    137deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.01) 100%
  ),
  rgba(0, 0, 0, 0.5);

$glass-stroke: linear-gradient(
  137deg,
  rgba(255, 255, 255, 0.25) 0%,
  rgba(255, 255, 255, 0.01) 100%
);

$glass-outside-stroke: rgba(255, 255, 255, 0.6);

$glass-inside-stroke: rgba(255, 255, 255, 0.4);

$glass-stroke-alt: radial-gradient(
    170.42% 50% at 50% 50%,
    #fff 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  radial-gradient(
    198.89% 50% at 50% 50%,
    #0a7abf 0%,
    rgba(255, 255, 255, 0.7) 100%
  ),
  radial-gradient(0% 50% at 50% 50%, #0f0f0f 0%, rgba(21, 21, 21, 0) 100%);

$glass-disabled: linear-gradient(
    137deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.01) 100%
  ),
  rgba(75, 75, 75, 0.75);

$glass-hologram: url(https://s3-alpha-sig.figma.com/img/e7a0/4d7e/b4024f01adca241cd391651b1a868550?Expires=1688947200&Signature=SOoQHh4Cz8dPvST7Yp8v0u2x70otgSbBcUVEIEpP3FEUox3QOs92tLDbsoEuFjk9R2NidfVD7y5O6ag7smOMkiX4m4phMGB1ErlII~x6821QVHLNQM0la1P6w9YmeXhdivE8SL6pWtSfYxfzSuARNM6KHFBpJ5vccbjwM7wq7R5KCru--WOtZflAmwY6epEhss8oHZPZaB0~M243S~ltHmkAHJ7dK5QGJ8gdOqlu3AZQYGyGnp4R6HG00UkcKpSbb1O7R0nKCWWuose8yIopuSqgLFuqAtaJ0tnMjhMm-di6kn5nZsEvS6Gxaw17uXqQJAK0fOu3bz38W~SnHRF0MA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4);

// --------------- GLOWS -----------------------

@mixin glow-primary-navy {
  background: $primary-navy;
  box-shadow: 0px 0px 64px 8px $primary-navy;
}

@mixin glow-primary-beige {
  background: $primary-beige;
  box-shadow: 0px 0px 64px 8px $primary-beige;
}

@mixin glow-secondary-pink {
  background: $secondary-pink;
  box-shadow: 0px 0px 64px 8px $secondary-pink;
}

@mixin glow-secondary-blue {
  background: $secondary-blue;
  box-shadow: 0px 0px 64px 8px $secondary-blue;
}

@mixin glow-secondary-gray-white {
  background: $secondary-gray-white;
  box-shadow: 0px 0px 64px 8px $secondary-gray-white;
}

@mixin glow-system-red-red {
  background: $system-red-red;
  box-shadow: 0px 0px 64px 8px $system-red-red;
}

@mixin glow-system-green-green {
  background: $system-green-green;
  box-shadow: 0px 0px 64px 8px $system-green-green;
}

@mixin glow-brand-blue {
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.4);
}

// --------------- MIXINS -----------------------

// ---- display ----

@mixin display-xl {
  font-family: Sequel100Black-65;
  font-size: 5.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 5.5rem */
  text-transform: uppercase;
}

@mixin display-l {
  font-family: Sequel100Black-65;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 5rem */
  text-transform: uppercase;
}

@mixin display-m {
  font-family: Sequel100Black-65;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 4.5rem */
  text-transform: uppercase;
}

@mixin display-s {
  font-family: Sequel100Black-65;
  font-size: 3.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 3.8125rem */
  text-transform: uppercase;
}

@mixin header-xl {
  font-family: Sequel100Black-55;
  font-size: 3.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 3.0625rem */
  text-transform: uppercase;
}

@mixin header-l {
  font-family: Sequel100Black-55;
  font-size: 2.4375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 2.4375rem */
  text-transform: uppercase;
}

@mixin header-m {
  font-family: Sequel100Black-55;
  font-size: 1.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.9375rem */
  text-transform: uppercase;
}

@mixin header-s {
  font-family: Sequel100Black-55;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.5625rem */
  text-transform: uppercase;
}

@mixin header-xs {
  font-family: Sequel100Black-65;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  text-transform: uppercase;
}

@mixin header-xxs {
  font-family: Sequel100Black-75;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1rem */
  text-transform: uppercase;
}

@mixin header-buttons {
  font-family: Sequel100Black-55;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-xl-bold {
  font-family: Roboto Mono;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-l-bold {
  font-family: Roboto Mono;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-m-bold {
  font-family: Roboto Mono;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-s-bold {
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-xs-bold {
  font-family: Roboto Mono;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

//  I don't think the regular weights are used anymore, tbc

@mixin body-xl {
  font-size: 25px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-l {
  font-size: 20px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-m {
  font-size: 16px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-s {
  font-size: 13px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-xs {
  font-size: 10px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-xl-light {
  font-family: Roboto Mono;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin body-l-light {
  font-family: Roboto Mono;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin body-m-light {
  font-family: Roboto Mono;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin body-s-light {
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin body-xs-light {
  font-family: Roboto Mono;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin body-xs-20 {
  font-family: "Roboto Mono";
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
}

// new styles feb 2024

@mixin rm-all-caps-xs {
  font-family: "Roboto Mono";
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
}

@mixin rm-all-caps-s {
  font-family: "Roboto Mono";
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.04063rem;
  text-transform: uppercase;
}

@mixin rm-body-light-m {
  font-family: "Roboto Mono";
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin rm-body-bold-m {
  font-family: "Roboto Mono";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin sequel-header-xs {
  font-family: Sequel100Black-65;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

@mixin sequel-header-s {
  font-family: Sequel100Black-65;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  text-transform: uppercase;
}

@mixin sequel-header-m {
  font-family: Sequel100Black-65;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1rem */
  text-transform: uppercase;
}

@mixin sequel-header-l {
  font-family: Sequel100Black-65;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  text-transform: uppercase;
}

@mixin sequel-header-xl {
  font-family: Sequel100Black-65;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.5625rem */
  text-transform: uppercase;
}

// --------------- borders ----------------------

$stroke-width-s: 0.5px;
$stroke-width-m: 1px;
$stroke-width-l: 1.5px;

@mixin stroke-border {
  position: relative;
  border: 0.5px solid $glass-outside-stroke;
}

@mixin stroke-border-m {
  border: 1px solid $glass-outside-stroke;
}

@mixin glass-background {
  border-radius: 1rem;
  background: var(
    --glass-background,
    linear-gradient(
      137deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.01) 100%
    ),
    rgba(0, 0, 0, 0.25)
  );
  /* Glass/Glass + Shadow */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
}

// --------------- GLOBAL -----------------------
.landing-container-center-content .wallet-adapter-button,
.landing-container-center-content .wallet-adapter-button-trigger {
  all: unset !important;
}

.landing-connect-button-container .wallet-adapter-button-start-icon {
  display: none !important;
}

.header-page {
  width: 100%;
  height: 100%;
}

.header-page-black {
  width: fit-content;
  min-width: 100%;
  min-height: 100%;
  height: fit-content;
  background: #0a0909;
}

.header-page-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.green {
  color: $ticketdex-green;
}

li:has(img[alt="Backpack icon"]) {
  display: none;
}
