@import "../css/global.scss";

.eventListLayout {
  @media (max-width: 960px) {
    padding-top: calc(80px + 1rem + 12px) !important;
  }
}

.eventListHeader {
  max-width: 1100px;
  margin: 0 auto;
  padding: 1.5rem;
  padding-bottom: 0;

  @media (max-width: 960px) {
    padding: 0;
  }
}

.eventListHeroContainer {
  display: flex;
  margin: 1rem 0;
}

.eventTagContainer {
  width: 100%;


}

.tagRow {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: 960px) {
    flex-direction:column;
    position: relative;
    width: calc(100%);

    &:first-of-type {
      margin-bottom: 0px;
    }
  }
}

.near {
  @media (max-width: 960px) {
    display:none;
  }
}

.topRow {
  h2 {
    @include header-xs;
    color: $neutral-white-alpha-50;
    margin-right: 1rem;
  }
}

.eventListBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1.5rem;
  padding-top: 0;

  @media (max-width: 960px) {
    padding: 0;
  }

  h2 {
    color: $primary-beige;
    @include header-s;
    margin: 0;
    margin-top: 3rem;
    margin-bottom: 1.5rem;

    @media (max-width: 960px) {
      @include header-xs;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
    }
  }

  :global(.loading) {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 10rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.loading {
  width: 21rem;
  height: 20rem;
  margin-bottom: 1.5rem;

  img {
    width: 100%;
    height: 13rem;
  }
}

.loadingTitle {
  width: 18rem;
  background-color: $neutral-black-alpha-75;
  padding: 1.5rem;
  border-radius: 0 0 1rem 1rem;
  margin-top: -2px;
  position: relative;

  img:first-of-type {
    border-radius: unset;
    width: calc(100% - 3rem);
    height: 1rem;
    margin-bottom: 0.5rem;
  }

  img:last-of-type {
    border-radius: unset;
    width: 50%;
    height: 1rem;
  }
}

.locationMenuItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.5rem;
  transition: 0.1s ease-in all;

  &:hover {
    border-radius: 0.25rem;
    background-color: $brand-blue-alpha-15;
  }

  span {
    @include body-m-light;
    color: $white;
  }
}

.selectedLocation {
  span {
    color: $brand-blue;
  }
  background-color: $brand-blue-alpha-15;
  svg {
    path {
      fill: $brand-blue;
    }
  }
}

.menuContainer {
  position: relative;
  margin-right: 1rem;

  @media (max-width: 960px) {
    position: unset;
    width:100%;
    box-sizing:border-box;
    margin-bottom: 8px;
    
    button {
      width:100%;
      justify-content:space-between;
      box-sizing:border-box;
    }
  }
}

.eventContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  max-width: 1100px;
  margin: 1.5rem auto;
  width: 100%;
}

.eventListCard {
  margin-bottom: 2rem;
  margin-right: 2.5rem;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @media (max-width: 960px) {
    margin-right: 0;
    width: 100%;
  }
}

.datePickerContainer {
  position: absolute;
  z-index: 2;
  top: 2.5rem;

  @media (max-width: 960px) {
    left: 0;
    top: 3rem;
  }
}

.showMoreButton {
  margin-top: 3rem;
  margin-bottom: 5rem;
  width: auto;
}

.emptyState {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;

  @media (max-width: 960px) {
    width: 100%;
  }

  h1 {
    @include header-s;
    color: $primary-beige;
    margin: 0;
    margin-top: -2rem;
  }

  p {
    @include body-s;
    color: $neutral-gray;
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.loadingStateContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;
  width: 1100px;

  img {
    border-radius: 1rem 1rem 0 0;
    @media (max-width: 960px) {
      width: 100%;
    }
  }
}

.eventTypeMenuContainer {
  border: 0.1px solid $neutral-white-alpha-15;
  border-radius: 0.75rem;
  background-color: $brand-dark-blue;
  padding: 1rem;
  position: absolute;
  top: 3.5rem;
  width: 25rem;
  z-index: 10;
  @include glow-brand-blue;

  .eventTypeMenuItem {
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    @include body-m-light;
    padding: 0.75rem;
    text-transform: capitalize;
    transition: 0.1s ease-in all;

    &:hover {
      border-radius: 0.25rem;
      background-color: $brand-blue-alpha-15;
    }
  }

  .selectedEventType {
    color: $brand-blue;
    background-color: $brand-blue-alpha-15;
  }

  @media (max-width: 960px) {
    width:100%;
    box-sizing:border-box;
  }

}

.locationMenuContainer {
  border-radius: 0.75rem;
  border: 1px solid $neutral-white-alpha-15;
  background-color: $brand-dark-blue;
  @include glow-brand-blue;
  position: absolute;
  padding: 1rem;
  top: 4rem;
  width: 25rem;
  z-index: 10;


  @media (max-width: 960px) {
    width:100%;
    box-sizing:border-box;
  }

  .inputContainer {
    align-items: center;
    display: flex;
    border: 1px solid $brand-blue;
    border-radius: 0.75rem;
    background-color: $brand-dark-blue;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    width: calc(100% - 2rem);

    svg {
      margin-right: 0.75rem;
      path {
        fill: $brand-blue;
      }
    }

    input {
      background-color: $brand-dark-blue;
      border: none;
      color: $white;
      @include body-s-light;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $brand-blue;
        @include body-s-light;
      }
    }
  }

  .locationMenuContent {
    border-top: 1px solid $neutral-white-alpha-15;
    padding-top: 1rem;

    button {
      @include body-s-light;
      text-transform: unset;
      padding: 0.75rem 1rem;
    }
  }
}

.compactEventCard {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
  transition: 0.1s all;

  &:hover {
    background-color: $brand-blue-alpha-15;
    border-radius: 0.75rem;
  }

  img {
    border-radius: 0.25rem;
    min-width: 6.625rem;
    width: 6.625rem;
    height: 4rem;
    object-fit: cover;
    object-position: center;
    margin-right: 1.5rem;
  }

  .compactEventTitle,
  .compactEventDate {
    display: flex;

    h3 {
      @include header-xxs;
      color: $primary-beige;
      margin: 0;
      margin-bottom: 0.5rem;
    }
    p {
      @include body-s;
      color: $neutral-gray;
      margin: 0;
    }
  }

  .compactEventDate {
    flex-direction: column;
    min-width: 116px;
  }

  .compactRightContent {
    align-items: center;
    display: flex;
  }

  .compactLeftContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 1rem;
  }

  .compactPrice {
    align-items: center;
    border: 0.5px solid $primary-beige;
    border-radius: 0.25rem;
    background-color: $brand-dark-blue;
    cursor: pointer;
    display: flex;
    margin-left: 1.5rem;
    padding: 1rem 1.5rem;
    transition: 0.1s all;
    width: 8rem;
    justify-content: center;

    &:hover {
      border: 0.5px solid $brand-blue;
      span {
        color: $brand-blue;
      }
      p {
        color: $brand-blue;
      }
    }

    span {
      @include body-s;
      color: $primary-beige;
      margin-right: 0.5rem;
      text-transform: uppercase;
    }

    p {
      color: $primary-beige;
      @include header-xxs;
      margin: 0;
    }
  }
}

.mobileEventCard {
  display: flex;
  margin-bottom: 1.5rem;

  .mobileEventDate {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    border-right: 1px solid $neutral-white-alpha-50;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
  }
  .mobileMonth {
    color: $primary-beige;
    @include header-buttons;
    text-transform: uppercase;
  }

  .mobileDay {
    color: $primary-beige;
    text-align: center;
    font-family: Sequel100Black-75;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 1.5rem */
    margin: 0;
    text-align: left;
    text-transform: uppercase;
  }

  .mobileTime {
    color: $neutral-white-alpha-50;
    @include body-xs;
  }

  .mobileRightContent {
    padding-left: 1rem;
    h3 {
      color: $primary-beige;
      @include header-xxs;
      margin: 0 0 0.5rem 0;
    }

    p {
      color: $neutral-gray;
      @include body-s;
      margin: 0;
    }

    .mobileTicketPrice {
      color: $system-green-green;
      text-transform: uppercase;
    }
  }
}

.browseMoreButton {
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: var(--spacing-8, 8px);
  border: 1px solid var(--Brand-Brand-Color, #85D5FF);
  background: var(--Brand-Dark-Blue, #001631);
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
  height:41px;
  box-sizing:border-box;
  color: var(--Brand-Light-Bljue, #85D5FF);
  font-family: "Roboto Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.6px;

  svg {
    margin-left: 12px;
  }
}

.eventTypeHeaderSection {
  display:flex;
  align-items:center;
  text-transform:uppercase;
  margin-bottom:24px;
  margin-top: 24px;




  h2 {
    margin:0px;
    padding:0px;
    margin-right:16px;
  }

  @media (max-width: 960px) {
    align-items: flex-start;
    flex-direction: column;

    h2 {
      margin-bottom: 1rem;
    }
  }
}


.bottomEventSection {
  width:100%;
}

.dateDropdown {
  display:none;
}
