@import "../../css/global.scss";

.select {
  border-radius: 0.25rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: $neutral-white-alpha-15;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  @include header-xxs;
  padding: 0.5rem 0.75rem;
  position: relative;
  transition: 0.1s all;
  width: 100%;

  &:hover {
    border: 1px solid $white;
  }

  svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.5rem;
  }
}

.selectOpen {
  color: $brand-blue;
  border: 0.5px solid $brand-blue;

  svg {
    path {
      fill: $brand-blue;
    }
  }
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.selectOptions {
  background-color: $brand-dark-blue;
  border-radius: 0.25rem;
  border: 0.5px solid $neutral-white-alpha-15;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 2.25rem;
  width: fit-content;
  min-width: 100%;
  z-index: 3;
}

.option {
  color: $white;
  cursor: pointer;
  display: flex;
  @include body-m-light;
  justify-content: center;
  padding: 1rem;
  text-align: center;

  &:hover {
    background: $brand-blue-alpha-15;
  }

  svg {
    margin-left: 0.5rem;
  }
}

.selected {
  color: $brand-blue;
  @include body-m-light;

  svg {
    path {
      fill: $brand-blue;
    }
  }
}
