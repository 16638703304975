@import "../css/global.scss";

.outerContainer {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
}

.velvet {
  @media (max-width: 960px) {
    padding: 0;
  }
}

.heroSection {
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 97px - 16.5rem);
  padding: 3.75rem;
  width: calc(100% - 7.5rem);

  @media (max-width: 960px) {
    background-position: right;
    height: 65vh;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    padding: 2rem 1.5rem;
    width: 100%;
  }

  .heroHeader {
    color: $primary-beige;
    font-family: Sequel100Black-55;
    font-size: 3rem;
    line-height: 100%;
    margin: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    width: 55%;

    @media (max-width: 960px) {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }

  .heroParagraph {
    color: $primary-beige;
    font-family: "Roboto Mono";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.04063rem;
    text-transform: uppercase;
    line-height: normal;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 55%;

    @media (max-width: 960px) {
      @include body-s-light;
      text-align: center;
      width: 100%;
    }
  }

  .heroButton {
    border-radius: 4px;
    @include header-xxs;
    margin-top: 1rem;
    padding: 1.5rem 3.75rem;

    @media (max-width: 960px) {
      font-size: 0.75rem;
      align-self: center;
      padding: 1rem 2rem;
    }
  }

  img {
    width: 10rem;
    object-fit: contain;
    max-height: 10rem;
    margin-bottom: 1.5rem;

    @media (max-width: 960px) {
      height: auto;
      margin: 0 auto 1.5rem auto;
      max-width: 10rem;
    }
  }

  .heroDisclaimerContainer {
    margin-top: 3rem;
    width: fit-content;
  }

  .heroDisclaimer {
    @include body-xs-light;
    color: $neutral-white-alpha-30;
    margin: 0 auto;
    text-align: left;

    @media (max-width: 960px) {
      margin-top: 5rem;
      position: absolute;
      bottom: 3rem;
      width: calc(100vw - 3rem);
    }
  }
}

.exchangeSection {
  display: flex;
  height: calc(100vh - 7rem);
  margin-bottom: 7rem;
  padding: 3.5rem 0;
  width: 100vw;

  @media (max-width: 960px) {
    height: 30rem;
    margin-bottom: 4rem;
    padding: 1.5rem;
    width: calc(100% - 2rem);
  }

  .exchangeContentLeft {
    position: relative;
    width: 60%;
    transform: translateX(-926px);
    transition: transform 1.5s ease-in;

    @media (max-width: 960px) {
      transform: translateX(-225px);
    }

    .tile {
      margin-right: 1.5rem;
      width: 120%;
      margin-left: calc(-1 * (25% + 1.5rem));
      margin-top: 3rem;

      @media (max-width: 960px) {
        width: 150%;
      }
    }

    .xpHeader {
      position: absolute;
      top: 10rem;
      left: 3.5rem;

      @media (max-width: 960px) {
        left: -1.5rem;
        top: 8rem;
      }

      h2 {
        color: $primary-beige;
        @include header-l;
        margin-bottom: 1rem;

        @media (max-width: 960px) {
          font-size: 1rem;
        }
      }
      img {
        filter: drop-shadow(0 0 1.5rem rgb(255, 255, 255));
        width: 120%;

        @media (max-width: 960px) {
          width: 110%;
        }
      }
    }
  }

  .exchangeContentRight {
    width: 50%;
    padding-right: 2.5rem;

    h2 {
      color: $primary-beige;
      @include header-l;

      .blue {
        color: $brand-blue;
        display: block;
      }

      @media (max-width: 960px) {
        display: none;
      }
    }

    // turn this into alternate button
    .exchangeLink {
      align-items: center;
      border-left: 1px solid $primary-yellow;
      color: $primary-beige;
      cursor: pointer;
      display: flex;
      margin-bottom: 1.5rem;
      padding: 0.75rem 1.5rem;
      position: relative;
      @include body-m-light;
      text-transform: uppercase;
      width: fit-content;
      z-index: 3;

      @media (max-width: 960px) {
        display: none;
      }

      &:hover {
        background: $neutral-white-alpha-50;
      }

      svg {
        margin-left: 1rem;
        path {
          fill: $primary-beige;
        }
      }
    }

    .ticket {
      position: absolute;
      right: 0;
      transform: translateX(300px);
      transition: transform 1.5s ease-in;
      margin-top: 1.8rem;

      @media (max-width: 960px) {
        height: 5rem;
        transform: translateX(200px);
      }
    }

    .exchangeContent {
      margin-top: calc(135px + 12rem);
      p {
        color: $primary-beige;
        @include body-m-light;
      }

      @media (max-width: 960px) {
        left: 1.5rem;
        margin-top: calc(135px + 10rem);
        position: absolute;
        width: calc(100% - 3rem);
        p {
          @include body-s-light;
          text-align: center;
        }
      }
    }
  }
}

:global(#exchange.active) {
  .exchangeContentLeft {
    transform: translateX(0);
    .tile {
      animation: bounce 2s ease-in-out infinite alternate;
      animation-delay: 1.5s;
    }

    .xpHeader {
      img {
        animation: fade 2s ease-in-out infinite alternate;
        animation-delay: 1.5s;
      }
    }
  }

  .exchangeContentRight {
    .ticket {
      transform: translateX(0);
    }
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.85;
  }
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(3rem);
  }
}

.banner {
  background: #100606;
  border-top: 0.5px solid #7d7d7d;
  border-bottom: 0.5px solid #7d7d7d;
  display: flex;
  height: 6.5rem;
  padding: 1.75rem 0;
  overflow: hidden;
  position: relative;

  @media (max-width: 960px) {
    height: calc(5rem - (2 * 0.875rem));
    margin-left: -1rem;
    padding: 0.875rem 0;
    width: calc(100% + 2rem);
  }

  .bannerContent {
    align-items: center;
    display: flex;

    h1 {
      color: $primary-beige;
      @include display-m;
      margin: 0 3rem;
      width: fit-content;
      display: inline;
      white-space: nowrap;

      @media (max-width: 960px) {
        font-size: 2.25rem;
      }
    }

    img {
      height: 6.5rem;

      @media (max-width: 960px) {
        height: 3.125rem;
        width: 3.125rem;
      }
    }
    transform: translateX(100%);
    animation: animate_text 12s linear infinite;
  }
}

@keyframes animate_text {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.eventContainer {
  width: calc(100vw - 7rem);
  min-height: calc(100vh - 7rem);
  padding: 3.5rem;

  @media (max-width: 960px) {
    height: fit-content;
    min-height: unset;
    padding: 2.5rem 0;
    width: 100%;
  }

  h1 {
    color: $primary-beige;
    @include display-s;
    margin: 0;
    text-align: center;

    @media (max-width: 960px) {
      @include header-l;
    }
  }

  button {
    @include header-xxs;
    margin: 1.5rem auto;
    padding: 1.5rem 2.5rem;
    transition: 0.2s all;
  }

  .eventListContainer {
    display: flex;
    justify-content: center;
    margin-left: -3.5rem;
    width: calc(100% + 7rem);

    @media (max-width: 960px) {
      margin-bottom: -3rem;
      margin-left: 0;
      width: 100%;
      overflow: hidden;
    }

    .eventList {
      display: flex;
      flex-direction: column;

      margin-right: 1rem;

      @media (max-width: 960px) {
        height: fit-content;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .eventCard {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        @media (max-width: 960px) {
          width: 6.5rem;
        }

        .eventImageContainer {
          position: relative;

          .eventImage {
            width: 15.75rem;
            height: 15.75rem;
            background-size: cover;
            background-position: center;

            @media (max-width: 960px) {
              height: 6.5rem;
              width: 6.5rem;
            }
          }

          .eventDateContainer {
            border: 1px solid $primary-beige;
            border-radius: 0.25rem;
            background: $glass-background;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
              0px 4px 8px 3px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(50px);
            padding: 0.25rem;
            position: absolute;
            bottom: 1rem;
            left: 1rem;

            .eventMonth {
              color: $primary-beige;
              text-align: center;
              font-family: Sequel100Black-85;
              font-size: 0.375rem;
              font-style: normal;
              font-weight: 400;
              line-height: 100%; /* 0.375rem */
              padding-bottom: 0.25rem;
              text-transform: uppercase;

              @media (max-width: 960px) {
                font-size: 0.25rem;
              }
            }

            .eventDay {
              border-top: 1px solid $primary-beige;
              color: $primary-beige;
              @include header-xxs;
              margin-left: -0.25rem;
              padding-top: 0.25rem;
              text-align: center;
              width: calc(100% + 0.5rem);
            }
          }
        }

        .eventInfo {
          align-items: center;
          display: flex;
          justify-content: space-between;

          .eventInfoLeft {
            h3 {
              color: $primary-beige;
              text-overflow: ellipsis;
              font-family: Sequel100Black-85;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: 100%; /* 0.75rem */
              text-transform: uppercase;
              margin-bottom: 0;
              text-wrap: nowrap;

              @media (max-width: 960px) {
                font-size: 0.3rem;
              }
            }

            p {
              color: $white;
              text-overflow: ellipsis;
              font-family: Roboto Mono;
              font-size: 0.625rem;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              margin-top: 0.25rem;
              margin-bottom: 0;

              @media (max-width: 960px) {
                font-size: 0.25rem;
              }
            }
          }

          .eventInfoRight {
            p {
              color: #5cc75c;
              font-family: Sequel100Black-85;
              font-size: 0.6875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 100%; /* 0.6875rem */
              text-transform: uppercase;

              @media (max-width: 960px) {
                font-size: 0.3rem;
              }
            }
          }
        }
      }
    }
  }

  .eventList1,
  .eventList5 {
    transition: transform 1.5s ease-in;
  }

  .eventList2,
  .eventList4 {
    margin-top: 3rem;
  }

  .eventList3 {
    transition: transform 1.5s ease-in;
    margin-top: 6rem;
  }
}

:global(#events.active) {
  .eventList1,
  .eventList5 {
    transform: translateY(3rem);
  }

  .eventList3 {
    transform: translateY(-3rem);
  }
}

.contentBlock {
  @media (min-width: 960px) {
    display: none;
  }
  padding: 2rem 1rem;
  .contentBlockInner {
    position: relative;
    padding: 0 1rem 2rem 1rem;
    .topLeftBorder {
      position: absolute;
      top: 0;
      left: 0;
    }

    .topRightBorder {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(90deg);
    }

    .bottomRightBorder {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }

    .bottomLeftBorder {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(270deg);
    }

    img {
      width: 100%;
    }

    h3 {
      color: $primary-beige;
      @include header-xs;
      margin: 0 auto;
      text-align: center;
    }

    p {
      color: $white;
      @include body-s-light;
      margin-bottom: 2.5rem;
      text-align: center;
    }

    button {
      border-radius: 4px;
      font-family: Sequel100Black-55;
      font-size: 12px;
      font-weight: 400;
      margin: 0 auto;
      text-transform: uppercase;
    }
  }
}

.defaultHeroHeader {
  color: $primary-beige;
  @include display-s;
  text-transform: uppercase;
  margin-bottom: 0;
  width: 55%;

  @media (max-width: 960px) {
    font-size: 2rem;
    text-align: center;
    width: 100%;
  }
}

.searchContainer {
  --angle: 0deg;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid $brand-blue;
  display: flex;
  position: relative;
  padding: 2px;
  position: relative;
  width: 430px;
  z-index: 1;
  background: $brand-dark-blue-alpha-15;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  color: $brand-blue;
  @include body-s-light;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  width: calc(100% - 1.5rem);
  transition: 0.1s all;
  text-transform: uppercase;

  svg {
    margin-right: 0.5rem;
    z-index: 1;
    path {
      fill: $brand-blue;
    }
  }

  @media (max-width: 960px) {
    width: calc(100% - 2rem);
    margin-right: 1rem;
  }
}

.isMobile {
  display: flex;
  width: 100%;
  @media (min-width: 960px) {
    display: none;
  }
}

.search {
  background: $brand-dark-blue-alpha-15;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  color: $brand-blue;
  border: 1px solid transparent;
  @include body-s-light;
  padding: 0.75rem 0.75rem 0.75rem calc(1rem + 21px);
  margin-left: -30px;
  width: calc(100% - 1rem);
  transition: 0.1s all;
  text-transform: uppercase;

  &::placeholder {
    color: $brand-blue;
  }

  &:focus {
    outline: none;
  }
}

.velvetSearch {
  top: 5.8rem !important;
  position: fixed !important;
}

.faqSection {
  display: flex;
  padding: 0 3.75rem 5rem 3.75rem;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 1rem;
  }

  .faqLeft {
    margin-right: 1.5rem;
    width: 40%;

    @media (max-width: 960px) {
      margin-bottom: 1.5rem;
      width: 100%;
    }

    h1 {
      color: $primary-beige;
      @include header-xl;

      @media (max-width: 960px) {
        @include header-m;
      }
    }

    p {
      color: $neutral-white-alpha-50;
      @include body-s-light;
      text-transform: uppercase;
    }

    .buttonContainer {
      display: flex;
      margin-top: 1.5rem;
      width: 100%;

      @media (max-width: 960px) {
        flex-direction: column;
      }

      a {
        text-decoration: none;
      }

      button {
        border: 1px solid $brand-blue;
        background-color: $brand-dark-blue;
        color: $brand-blue;
        font-family: "Roboto Mono";
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.1625rem;
        padding: 0.75rem 1rem;

        svg {
          path {
            transition: 0.1s all;
          }
        }

        &:hover {
          background-color: $brand-blue;
          color: $brand-dark-blue;

          svg {
            path {
              fill: $brand-dark-blue;
            }
          }
        }
      }

      .telegram {
        border: 1px solid #039be5;
        color: #039be5;

        margin-left: 0.75rem;

        @media (max-width: 960px) {
          margin-left: 0;
          margin-top: 0.75rem;
        }

        span {
          align-items: center;
          display: flex;
        }

        img {
          width: 1rem;
          height: 1rem;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.faqRight {
  width: calc(60% - 1.5rem);

  @media (max-width: 960px) {
    width: 100%;
  }
}

.faqCollapsible {
  border-radius: 0.75rem;
  border: 0.5px solid $brand-blue;
  background-color: $brand-dark-blue;
  margin-bottom: 1rem;
  width: 100%;

  .faqQuestion {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;

    h3 {
      color: $primary-beige;
      font-family: "Roboto Mono";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.25rem;
      margin: 0;
      text-transform: uppercase;
      text-wrap: pretty;
      transition: 0.1s all;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-left: 1rem;
      path {
        transition: 0.1s all;
      }
    }

    &:hover {
      h3 {
        color: $brand-blue;
      }

      svg {
        path {
          fill: $brand-blue;
        }
      }
    }
  }

  .questionOpen {
    h3 {
      color: $brand-blue;
    }
  }

  .faqAnswer {
    color: $primary-beige;
    @include body-m-light;
    padding: 0 1.5rem 1.5rem 1.5rem;

    p:first-of-type {
      margin-top: 0;
    }

    a {
      color: $primary-beige;
    }
  }
}
