@import '../../css//global.scss';

.searchContainer {
    background-color: $brand-dark-blue;;
    height: calc(100vh - 8rem);
    position: absolute;
    top: 3.8rem;
    padding: 1rem;
    left: 0;
    width: calc(100% - 2rem);
    z-index: 10;
}

.emptyState {
    color: $primary-beige;
    @include rm-all-caps-s;
    padding: 5rem 0;
    text-align: center;

    > div {
        margin-bottom: 1.5rem;
    }

    .icon {
        font-size: 1.9375rem;
    }

}