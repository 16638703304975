@import "../../css/global.scss";

.container {
  align-items: center;
  border-radius: 0.75rem 0.75rem 0 0;
  background: $brand-blue-alpha-15;
  backdrop-filter: blur(12.5px);
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  width: calc(100% - 2rem);
}

.containerApplied {
  background: $system-green-alpha-15;
}

.containerLeft {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  span {
    @include body-l-bold;
    margin-right: 1rem;
  }
}

.leftTopText {
  color: var(--brand-beige, #fdfcea);
  @include body-s-bold;
  margin-bottom: 0.25rem;
}

.appliedText {
  color: var(--system-green-green, #19b963) !important;
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
}

.leftBottomText {
  color: var(--brand-beige, #fdfcea);
  @include body-xs-light;
}

.applyDiscountButton {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include body-xs-bold;
  height: 1.3125rem;
  box-sizing: border-box;
  padding: 0px 8px;
  cursor: pointer;
}

.checkmark {
  margin-left: 5px;
}

.removeButton {
  cursor: pointer;
  border-radius: 4px;
  border: 0.5px solid var(--brand-beige, #fdfcea);
  backdrop-filter: blur(12.5px);
  display: flex;
  padding: var(--spacing-4, 4px) var(--spacing-8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8, 8px);
  color: var(--brand-beige, #fdfcea);
  text-align: center;
  @include body-xs-bold;
}

.minimumNotMet {
  color: var(--System-Caution-Caution, #f90);
  font-family: "Roboto Mono";
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items:center;
  justify-content:space-between;
  padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
  gap: var(--spacing-16, 16px);

  background: var(--System-Caution-Alpha-15, rgba(255, 153, 0, 0.15));
  /* Blur - 25 */
  backdrop-filter: blur(12.5px);
  border-top-left-radius: var(--spacing-12, 12px);
  border-top-right-radius: var(--spacing-12, 12px);
}

.minimumNotMetLeft {
  align-items:flex-start;
  justify-content:flex-start;
  flex-direction: column;
  display:flex;
}


.minimumNotMetTop {
  color: var(--System-Caution-Caution, #F90);
  /* RM All Caps/S */
  font-family: "Roboto Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  margin-bottom:12px;
}


.minimumNotMetBottom {
  color: var(--System-Caution-Caution, #F90);
  /* RM Body/Light/XS */
  font-family: "Roboto Mono";
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
