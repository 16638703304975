@import "../../css/global.scss";

.dateContainer {
  background: $brand-dark-blue;
  border-radius: 0.75rem;
  padding-top: 2rem;
  border: 0.5px solid $neutral-white-alpha-15;
  width: fit-content;
  @include glow-brand-blue;

  button {
    border-radius: 0.25rem;
    
    &:not(:disabled) {
      text-transform: uppercase;
    }
  }
}

:global(.react-datepicker__navigation--previous) {
  left: 2.5rem;
}

:global(.react-datepicker__navigation--next) {
  right: 2.5rem;
}


:global(.react-datepicker) {
  border: unset;
  color: $white;
  background: $brand-dark-blue;
  border-radius: 16px;
  padding: 0 2rem;

}

:global(.react-datepicker__current-month),
:global(.react-datepicker__day-name),
:global(.react-datepicker__day) {
  color: $white;
}

:global(.react-datepicker__current-month) {
  @include header-xxs;
  margin-bottom: 1rem;
}

:global(.react-datepicker__day-name),
:global(.react-datepicker__day) {
  @include body-l-light;
  width: 48px;
  line-height: 48px;

  @media (max-width: 960px) {
    width: 40px;
  }
}

:global(.react-datepicker__header) {
  background: $brand-dark-blue;
  border-bottom: unset;
  border-radius: 16px !important;
}

:global(.react-datepicker__day--disabled),
:global(.react-datepicker__day--outside-month) {
  color: #9e9e9e;
  @include body-l-light;
  line-height: 48px;
}

:global(.react-datepicker__day-names) {
  border-bottom: 1px solid $white;
}

:global(.react-datepicker__month) {
  margin: 0;
}

:global(.react-datepicker__day--selected) {
  background: $brand-blue;
  border-radius: 24px;
  color: $neutral-black;

  &:hover {
    border-radius: 24px;
    background: $brand-blue-alpha-15;
  }
}

:global(.react-datepicker__day--in-range) {
  background: $brand-blue;
  border-radius: 0px;
  color: $neutral-black;

  &:hover {
    color: $neutral-black;
    border-radius: 0px;
    background: $brand-blue;
  }
}

:global(.react-datepicker__day--range-start) {
  background: $brand-blue;
  border-radius: 24px 0px 0px 24px;
  color: $neutral-black;

  &:hover {
    border-radius: 24px 0px 0px 24px;
    background: $brand-blue-alpha-15;
  }
}

:global(.react-datepicker__day--range-end),
:global(.react-datepicker__day--in-range .react-datepicker__day--weekend) {
  background: $brand-blue;
  border-radius: 0px 24px 24px 0px;
  color: $neutral-black;

  &:hover {
    border-radius: 0px 24px 24px 0px;
    background: $brand-blue-alpha-15;
  }
}

:global(.react-datepicker__day-in-range),
:global(.react-datepicker__day-in-selecting-range) {
  :nth-of-type(1):nth-last-of-type(1) {
    border-radius: 24px;
  }
}

:global(.react-datepicker__day--in-selecting-range) {
  border-radius: 0px;
  background: $brand-blue !important;
  color: $neutral-black;
}

:global(.react-datepicker__day--selecting-range-start) {
  border-radius: 24px 0px 0px 24px;
  background: $brand-blue;
  color: $neutral-black;
}

:global(.react-datepicker__day--selecting-range-end) {
  border-radius: 0px 24px 24px 0px;
  background: $brand-blue;
  color: $neutral-black;
}

:global(.react-datepicker__day) {
  &:hover {
    background: $brand-blue-alpha-15;
  }
}

.buttonContainer {
  border-radius: 0 0 0.75rem 0.75rem;
  background-color: $brand-blue-alpha-15;
  padding: 1rem;
}