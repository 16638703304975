@import "../../css/global.scss";

.footer {
  padding: 10rem 3.5rem 2.5rem 3.5rem;
  position: relative;

  @media (max-width: 960px) {
    padding: 2.5rem 1rem;
    width: 100%;
    margin-left: -1rem;
  }

  .footerConnect {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;

    h3 {
      color: $primary-beige;
      @include header-xxs;
      text-transform: uppercase;
    }

    img {
      width: 3rem;
      height: 3rem;
    }
  }

  .footerLinkContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 8rem;
    width: 50%;

    @media (max-width: 960px) {
      width: 100%;
    }

    a,
    .footerLink {
      color: $primary-beige;
      cursor: pointer;
      font-family: Roboto Mono;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      text-decoration: none;

      &:hover {
        color: $brand-blue;
        text-shadow: 1px 0px 4px $brand-blue;
      }
    }
  }

  .footerLogoContainer {
    background-color: $primary-beige;
    display: flex;
    padding: 2.5rem 3.5rem;
    width: 100%;
    margin-left: -3.5rem;
    margin-bottom: -3.5rem;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      margin-left: -1rem;
      padding: 2.5rem 1rem;
      width: 100%;
    }

    img {
      margin-bottom: 1rem;
      width: 50%;

      @media (max-width: 960px) {
        width: 9rem;
      }
    }

    a {
      color: $neutral-black;
      @include body-s-bold;
      text-decoration: none;
    }
  }

  .logo {
    display: flex;
    flex-direction: column;  
  }

  .footerForm {
    h3 {
      @include header-s;
      margin: 0 0 0.5rem 0;
      width: 100%;

      @media (max-width: 960px) {
        width: 75%;
      }
    }

    .label {
      color: $neutral-black;
      @include body-xs-light;
      margin-bottom: 0.5rem;
    }

    .disclaimer {
      color: #000;
      font-family: Roboto Mono;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    input {
      @include body-s-light;
      width: 100%;
    }
    button {
      z-index: 0;
    }

    @media (max-width: 960px) {
      margin-bottom: 2rem;
    
    }
  }
}
