@import "../css/global.scss";

.eventPage {
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  padding: 0 1rem;

  @media (max-width: 960px) {
    height:100%;
    :global(#layoutContainer) {
      padding: 0;
      height: 100vh;
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }
  }
}

.ticketListHeader {
  align-items: center;
  display: flex;
  padding: 1rem;

  @media (max-width: 960px) {
    background-color: $brand-darker-blue;
    padding: 0.5rem 1.5rem;

    svg {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 1rem;

      path {
        fill: $white;
      }
    }

  }

  img {
    border-radius: 0.5rem;
    width: 4.5rem;
    height: 4.5rem;
    object-fit: cover;
    object-position: center;
    margin-right: 0.75rem;

    @media (max-width: 960px) {
      width: 3.25rem;
      height: 3.25rem;
    }
  }
}

.disclaimer {
  background: $neutral-black;
  color: $white;
  text-align: center;
  font-family: Roboto Mono;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0.5rem 1.5rem;
}

.eventInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media (max-width: 960px) {
    flex-direction: column;

    img {
      display: none;
    }
  }
}

.ticketListContainer {
  background: $brand-dark-blue;
  height: 100%;
  min-width: 32.25rem;
  width: 40%;

  @media (max-width: 960px) {
    background-color: $brand-dark-blue;
    max-width: unset;
    min-width: unset;
    width: 100%;
  }
}

.eventPageContainer {
  display: flex;
  height: calc(100vh - 100px);
  margin: 0 auto 2rem auto;
  overflow: hidden;

  @media (max-width: 960px) {
    height: 100vh;

    margin-left: -1rem;
    overflow: unset;
    width: calc(100% + 2rem);
  }
}

.shouldScrollOuter {
  overflow-y: scroll;

  @media (min-width: 960px) {
    overflow-y: hidden;
  }
}

.shouldScroll {
  overflow-y: scroll;
  padding-top: 0;

  @media (min-width: 960px) {
    margin-top: 0;
    overflow-y: hidden;
  }
}

.info {
  padding: 0.5rem 0.75rem;
}

@media (max-width: 960px) {
  .desktopInfo {
    display: none;
  }
}

.date {
  color: $brand-blue;
  @include rm-all-caps-s;

  @media (max-width: 960px) {
  }
}

.eventInfo {
  margin-top: auto;
  width: 100%;

  h1 {
    color: $primary-beige;
    @include sequel-header-s;
    margin: 0.5rem 0;

    @media (max-width: 960px) {
      margin: 0.25rem 0;
    }
  }

  @media (max-width: 960px) {
    background: unset;
  }
}

.mobileEventInfoContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;

  svg {
    cursor: pointer;
    width: 1rem;
    height: 1rem;

    path {
      fill: $white;
    }
  }

  @media (min-width: 960px) {
    .angle,
    .info {
      display: none;
    }
  }
}

.eventInfoMobileWrapper {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 6.1%,
    rgba(0, 0, 0, 0.25) 50.62%,
    rgba(0, 0, 0, 0.3) 58.43%,
    rgba(0, 0, 0, 0.4) 68.98%,
    rgba(0, 0, 0, 0.5) 81.87%,
    rgba(0, 0, 0, 0.6) 93.19%,
    rgba(0, 0, 0, 0.7) 105.69%,
    rgba(0, 0, 0, 0.8) 117.46%,
    #000 124.84%
  );
  height: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @media (max-width: 960px) {
    background: unset;
    position: relative;
  }
}

.mobileVenueImageContainer {
  display: none;
  @media (max-width: 960px) {
    background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Crowd3+1.png);
    background-position: center;
    background-size: cover;
    display: flex;
    height: 13rem;
    position: relative;

    button {
      position: absolute;
      left: 1.5rem;
      z-index: 4;
    }
  }
}

.seatmapOpen {
  height: 15rem;
}

.venue {
  color: $neutral-white-alpha-50;
  display: flex;
  @include rm-all-caps-s;

  svg {
    cursor: pointer;
    min-width: 1.125rem;
    min-height: 1.125rem;
    margin-left: 0.5rem;
    path {
      fill: $neutral-white-alpha-50;
    }
  }

  @media (max-width: 960px) {
    font-size: 0.6rem;
  }
}

.select {
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid $brand-blue;
  box-sizing: border-box;
  color: $brand-blue;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-family: Roboto Mono;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.125rem;
  padding: 0.75rem 1rem;
  position: relative;
  transition: 0.1s all;
  text-transform: uppercase;

  &:hover {
    background-color: $brand-blue-alpha-15;
  }

  svg {
    margin-left: 0.5rem;
    width: 0.75rem;
    height: 0.75rem;

    path {
      fill: $brand-blue;
    }
  }
}
.iconLeft {
  position: absolute;
  top: 30%;
  right: 1rem;
}

.optionsContainer {
  display: flex;
  margin-right: 0.5rem;
  position: relative;

  @media (max-width: 960px) {
    margin-right: 1rem;
  }
}

.selectOptions {
  background-color: $neutral-black;
  border-radius: 0.25rem;
  border: 0.5px solid $neutral-white-alpha-15;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  height: 15rem;
  overflow: scroll;
  position: absolute;
  top: 3.25rem;
  width: 100%;
  z-index: 3;
}

.selectItem {
  color: $white;
  display: flex;
  @include body-s-light;
  justify-content: space-between;
  padding: 1rem;

  &:hover {
    background: #292929;
  }
}

.selected {
  color: $primary-yellow;
  @include body-s-bold;

  svg {
    path {
      fill: $primary-yellow;
    }
  }
}

.filterButton {
  border-radius: 0.5rem;
  border: 1px solid $brand-blue;
  background: $brand-dark-blue;
  font-family: Roboto Mono;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.125rem;
  padding: 0.75rem 1rem;

  svg {
    path {
      fill: $brand-blue;
    }
  }
}

.filters {
  display: flex;
  padding: 1rem 1rem;

  @media (max-width: 960px) {
    padding: 0.75rem 1.5rem;
  }
}

.ticketInfoListItem {
  align-items: center;
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 1rem;
  position: relative;
  transition: 0.1s all;
  z-index: 2;

  @media (max-width: 960px) {
    &:last-of-type {
      margin-bottom: 18rem;
    }
  }

  &:hover {
    background: $brand-blue-alpha-15;
    border-radius: 0.75rem;
  }
}

.listHeader {
  @include body-s-bold;
  border-bottom: 1px solid #3b3d41;
  color: $white;
  padding: 0.5rem;
  text-align: center;
}

.ticketGroupList {
  height: calc(100% - 12rem - 98px);
  overflow: scroll;
  padding: 0.5rem 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 460px) {
    max-height: 100%;
    min-height: calc(100% - 21.5rem - 140px);
    padding: 0.5rem 1.5rem;
  }
}

.fullHeightList {
  height: 100%;
}

.ticketInfo {
  align-items: center;
  color: $white;
  display: flex;

  @media (max-width: 960px) {
    justify-content: space-between;
    margin-top: 0.5rem;
  }
}

.ticketQuantity {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.05);
  border-radius: 0.25rem;
  color: $neutral-gray;
  display: flex;
  @include body-s-light;
  margin-right: 1rem;
  padding: 0.25rem 0.5rem;

  svg {
    margin-right: 0.5rem;
    path {
      fill: $white;
    }
  }


}

.ticketType {
  color: $neutral-white-alpha-50;
  display: flex;
  @include body-s;
  text-transform: uppercase;

  .section,
  .row {
    display: flex;
    flex-direction: column;
  }
}

.sectionNumber {
  color: $primary-beige;
  @include header-xxs;
}

.divider {
  width: 1px;
  background-color: $neutral-white-alpha-50;
  margin: 0 1rem;
  transform: rotate(20deg);
}

.priceDescription {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: $neutral-gray;
  @include body-s-light;
  margin: 0;
  padding: 0.5rem;
  transition: 0.3s all;
}

.tag {
  height: 26px;
  text-transform: unset;
}

.price {
  @include header-xxs;
  color: $primary-beige;
  margin-right: 0.25rem;
}

.currencyIcon {
  align-items: center;
  border-left: 0.5px solid $neutral-white-alpha-30;
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: 0 0.25rem;

  &:last-of-type {
    padding-right: 0;
  }

  img,
  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  svg {
    padding: 0 0.25rem;
  }

  img {
    padding-left: 0.25rem;
  }
}

.ticketActions {
  align-items: center;
  color: $neutral-gray;
  display: flex;
  @include body-s;
}

.ticketDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  padding-right: 1rem;
}

.quantitySelector {
  position: relative;
  .select {
    width: unset;
    padding-right: 1rem;
    padding-left: 3rem;
    margin-right: 1rem;
  }
}

.imageContainer {
  align-items: center;
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Crowd3+1.png);
  display: flex;
  height: 100%;
  padding: 1rem;
  width: 60%;
  width: -webkit-fill-available;

  @media (max-width: 960px) {
    display: none;
  }
}

.venueImage {
  height: 100%;
  z-index: 2;
  width: 100%;

  > div {
    > div {
      > div:last-of-type {
        > div {
          border: unset !important;
        }
      }
    }
  }

  @media (max-width: 960px) {
    height: auto !important;
    width: 100%;

    img {
      max-height: 15rem;
      object-fit: cover;
    }
  }
}

.tradableToggle {
  border-radius: 0.25rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: $grays-card-background;
  display: flex;
  padding: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
}

.tradableActive {
  background-color: $primary-yellow-alpha-15;

  svg {
    path {
      fill: $primary-yellow;
    }
  }
}

.ticketSelectorModal {
  background-color: $brand-dark-blue;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.4);
  border: 0.5px solid $brand-blue;
  border-radius: 1rem;
  position: absolute;
  left: calc((100vw - 343px) / 2);
  top: calc((100vh - 371px) / 2);
  margin: unset;
}

.ticketSelector {
  height: calc(100% - 21.5rem);
  min-height: 285px;
  padding: 2rem;
  border-radius: 1rem;

  @media (max-width: 960px) {
    min-height: 371px;
  }

  h2 {
    color: $primary-beige;
    @include header-xs;
    text-align: center;
  }

  .stepperContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2.5rem;
  }

  .selectedQuantity {
    color: $primary-beige;
    @include header-xl;
    margin: 0 1.5rem;
  }

  .stepperButton {
    align-items: center;
    border-radius: 0.5rem;
    border: 0.5px solid $primary-beige;
    background: $neutral-white-alpha-15;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.81rem;
    width: fit-content;
  }
}

.zeroHeight {
  height: 0px !important;
}

@media (max-width: 960px) {
  :global(.zoom-in-icon) {
    display: none;
  }

  :global(.slide-icon) {
    display: none;
  }
}

.seatDisclaimer {
  align-items: center;
  color: $white;
  display: flex;
  justify-content: center;
  @include body-xs-light;
  margin-bottom: 1.5rem;

  svg {
    &:first-of-type {
      margin-left: 0.5rem;
    }
  }
}

.seatButtonContainer {
  display: flex;
  justify-content: space-between;

  button {
    height: fit-content;
    width: calc(50% - 0.75rem);
  }
}

.sortMenuContainer {
  position: relative;
}

.sortMenu {
  background-color: $brand-dark-blue;
  border-radius: 0.75rem;
  border: 1px solid $neutral-white-alpha-15;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.40);
  position: absolute;
  padding: 1rem;
  text-wrap: nowrap;
  width: 13rem;
  z-index: 10;

  .sortOption {
    align-items: center;
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    @include rm-all-caps-s;
    padding: 0.75rem 0.5rem;
    text-transform: uppercase;
    transition: 0.1s all;

    &:hover {
      background-color: $brand-blue-alpha-15;
      border-radius: 0.25rem;
      color: $brand-blue;

    }
  }

  .selectedSortOption {
    background-color: $brand-blue-alpha-15;
    border-radius: 0.25rem;
    color: $brand-blue;
  }
}

.sortRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 1rem;

  @media (max-width: 960px) {
    padding: 0 2rem;
  }

  .listingCount {
    color: $primary-beige;
    @include rm-all-caps-s;

    @media (max-width: 960px) {
      @include rm-all-caps-xs;
    }
  }

  .sortButtonsContainer {
    display: flex;
  }

  .sortButton {
    align-items: center;
    border-radius: 0.5rem;
    box-sizing: border-box;
    backdrop-filter: blur(25px);
    border: 1px solid transparent;
    background-color: $brand-blue-alpha-15;
    color: $brand-blue;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    @include rm-all-caps-xs;
    padding: 0.75rem 1rem;
    position: relative;
    transition: 0.1s all;
    text-transform: uppercase;

    &:hover {
      border: 1px solid $brand-blue;
    }

    svg {
      margin-left: 0.5rem;
      path {
        fill: $brand-blue;
      }
    }
  }

  .clear {
    align-items: center;
    color: $brand-blue;
    cursor: pointer;
    display: flex;
    @include rm-all-caps-xs;
    padding: 0.75rem 1rem;
    margin-left: 0.5rem;

    @media (max-width: 960px) {
      padding: 0.75rem 0.5rem;
    }

    svg {
      width: 0.625rem;
      height: 0.625rem;
      margin-left: 0.5rem;
      path {
        fill: $brand-blue;
      }
    }


    &:hover {
      border-radius: 0.5rem;
      background-color: $brand-blue-alpha-15;
    }
  }
}

.priceRangeContainer {
  position: relative;

  @media (max-width: 960px) {
    margin-right: 1rem;
  }
}

.noTickets {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    color: $primary-beige;
    @include sequel-header-xl;
    margin-top: -2rem;
  }

  p {
    color: $grays-mains-gray;
    @include body-s;

  }
}