@import "../../css/global.scss";

.input {
  background: $brand-blue-alpha-15;
  border: 0.5px solid $neutral-white-alpha-15;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #9c9da1;
  @include body-s-bold;
  height: 51px;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
  padding: 1rem;
  transition: all 0.3s;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $neutral-white-alpha-50;
    @include body-s-light;
  }

  &:hover {
    border: 0.5px solid $brand-blue;
  }

  &:active,
  &:focus {
    border: 0.5px solid $white;
  }


  &:disabled {
    background: $brand-blue-alpha-15;
    border: 0.5px solid $neutral-white-alpha-15;
    color: $glass-inside-stroke;
    cursor: not-allowed;

    &:hover {
      background: $neutral-input-box !important;
      border: 0.5px solid $neutral-white-alpha-15 !important;
    }
  }

}
