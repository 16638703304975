@import "../css/global.scss";

.contentContainer {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 1.5rem 0;

  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 0;
    margin-top: 1.5rem;
    width: 100%;
  }
}

.container {
  padding-top: 3rem; // temporary
  margin: 0 auto;

  @media (max-width: 960px) {
    padding-top: 0;
    margin-top: -1rem;
  }
}

.back {
  color: $neutral-white-alpha-50;
  cursor: pointer;
  text-shadow: 0px 8px 50px rgba(133, 213, 255, 0.4);
  font-family: "Roboto Mono";
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0rem; /* 0% */
  margin: 0 auto 1.5rem auto;
  text-transform: uppercase;
  width: 54rem;

  @media (max-width: 960px) {
    width: 100%;
  }

  svg {
    path {
      fill: $neutral-white-alpha-50;
    }
  }
}

.pageHeading {
  @include header-s;
  margin: 0 auto 0.5rem auto;
  position: relative;
  width: 54rem;

  @media (max-width: 960px) {
    width: 100%;
  }

  svg {
    width: 2rem;
    height: 2rem;
    right: 0;

    @media (max-width: 960px) {
      top: 0;
    }
  }
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  width: 54rem;
  margin: 0 auto;

  @media (max-width: 960px) {
    width: 100%;
  }

  svg {
    width: 2rem;
    height: 2rem;
    right: 0;

    @media (max-width: 960px) {
      top: 0;
    }
  }
}

.sealed {
  color: $system-yellow-yellow;
  svg {
    path {
      fill: $system-yellow-yellow;
    }
  }
  path {
    fill: $system-yellow-yellow;
  }
}

.unsealed {
  color: $system-green-green;

  svg {
    path {
      fill: $system-green-green;
    }
  }

  path {
    fill: $system-green-green;
  }
}

.pageSubtitle {
  color: $white;
  @include body-xs-light;
  margin: 0 auto 0 auto;
  width: 54rem;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.contentLeft {
  border-radius: 0.75rem;
  background-color: rgba(250, 250, 250, 0.05);
  backdrop-filter: blur(5px);
  width: 33rem;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.showInfo {
  border-bottom: 0.5px solid $neutral-white-alpha-15;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  .showImage {
    width: 7.5rem;
    object-fit: cover;

    @media (max-width: 960px) {
      height: 12.5rem;
      width: 100%;
    }
  }

  .showDetails {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 1rem;

    @media (max-width: 960px) {
      flex-direction: column;
      padding: 1.5rem 1rem;
    }

    .title {
      color: $primary-beige;
      @include sequel-header-m;
      margin-bottom: 0.5rem;
      text-overflow: ellipsis;
    }

    .venue {
      color: $neutral-white-alpha-50;
      @include rm-all-caps-xs;
      letter-spacing: 0.03125rem;
      text-transform: uppercase;
    }

    .date {
      color: $brand-blue;
      font-family: Sequel100Black-65;
      font-size: 0.5625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    .showDetailsRight {
      min-width: 6rem;
      width: 6rem;
      text-align: right;

      @media (max-width: 960px) {
        text-align: left;
        width: 100%;
      }
    }

    .time {
      color: $neutral-white-alpha-50;
      @include body-xs;
      letter-spacing: 0.03125rem;
      text-transform: uppercase;
    }
  }
}

.contentRight {
  border-radius: 0.75rem;
  background-color: rgba(250, 250, 250, 0.05);
  backdrop-filter: blur(5px);
  margin-left: 1rem;
  width: 20rem;

  @media (max-width: 960px) {
    margin-left: 0;
    margin-top: 1.5rem;
    width: 100%;
  }

  .orderMetadataRow {
    border-bottom: 0.5px solid $neutral-white-alpha-15;
    color: $neutral-white-alpha-50;
    display: flex;
    justify-content: space-between;
    @include body-s;
    letter-spacing: 0.04063rem;
    padding: 1rem;
  }

  .orderPrice {
    padding: 1rem;

    .priceRow {
      display: flex;
      justify-content: space-between;
      @include body-s;
      letter-spacing: 0.04063rem;
      margin-bottom: 0.5rem;
    }

    .subtotal {
      color: $neutral-white-alpha-50;
    }

    .discount {
      color: $system-green-green;
    }

    .total {
      color: $white;
      font-family: "Roboto Mono";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.04063rem;
      text-transform: uppercase;
    }

    .disclaimer {
      border-top: 0.5px solid $neutral-white-alpha-15;
      color: $neutral-white-alpha-50;
      text-align: center;
      font-family: "Roboto Mono";
      font-size: 0.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.025rem;
      margin-top: 0.25rem;
      padding-top: 0.5rem;
      text-transform: uppercase;
    }
  }
}

.seatInfoContainer {
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.seatInfo {
  display: flex;
}

.section,
.row {
  color: $neutral-white-alpha-50;
  display: flex;
  flex-direction: column;
  @include body-s;
  text-transform: uppercase;

  .value {
    color: $primary-beige;
    @include header-xs;
    margin-top: 0.5rem;
  }
}

.divider {
  width: 1px;
  background-color: $neutral-white-alpha-50;
  margin: 0 1rem;
  transform: rotate(20deg);
}

.ticketInfoRight {
  align-items: center;
  display: flex;
}

.ticketQuantityContainer {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.05);
  border-radius: 0.25rem;
  color: $white;
  display: flex;
  font-family: Sequel100Black-65;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.25rem 0.5rem;

  @media (max-width: 960px) {
    margin-left: 1rem;
  }

  svg {
    margin-right: 0.5rem;
    path {
      fill: $white;
    }
  }
}

.receiveButton {
  box-shadow: 0px 8px 50px 0px rgba(133, 213, 255, 0.4);
  margin: 0 auto;
  width: 54rem;

  @media (max-width: 960px) {
    margin-top: 1.5rem;
    width: 100%;
  }
}

.secondaryButton {
  box-shadow: unset;
}

.successBanner {
  border-radius: 1rem;
  background-color: $system-green-alpha-15;
  border: 1px solid $system-green-green;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  width: 51rem;
  margin: 1.5rem auto 2.5rem auto;

  @media (max-width: 960px) {
    margin-top: 0;
    margin-bottom: 1.5rem;
    width: calc(100% - 3rem);

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  h1 {
    color: $system-green-green;
    @include header-xs;
    margin: 0;
  }
}

.unsealLoading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $white;
  margin-bottom: 10rem;

  @media (max-width: 960px) {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    color: $primary-beige;
    @include header-s;
    margin: 3rem 0 1rem 0;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    color: $white;
    @include body-s-light;
    text-align: center;
    margin: 0;
    margin-bottom: 0;
  }

  img {
    border-radius: 0.5rem;
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
    margin-top: 10rem;
  }
}
