@import "../css/global.scss";

.deliveryContainer {
  width: 100%;

  h1 {
    color: $primary-beige;
    @include header-xs;
  }

  p {
    color: $white;
    @include body-xs-light;
  }
}

.deliveryHeader {
  background-color: $brand-blue-alpha-15;
  backdrop-filter: blur(12.5px);
  border-radius: 0.75rem 0.75rem 0 0;
  color: $primary-beige;
  @include body-xs-light;
  padding: 0.75rem 1rem;

  h1 {
    color: $brand-blue;
    @include body-s;
    letter-spacing: 0.04063rem;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }
}

.deliveryDetails {
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0.75rem;

  scroll-margin-top: 130px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.deliveryForm {
  padding: 0 1rem;
}

.inputRow {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .inputContainer {
    width: calc(50% - 1rem);

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  .inputLabel {
    @include body-xs;
    color: $neutral-white-alpha-50;
    letter-spacing: 0.03125rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}

.guarantee {
  border-top: 0.5px solid $neutral-white-alpha-15;
  color: $brand-blue;
  @include body-xs-light;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  position: relative;

  svg {
    position: absolute;
    right: 1.5rem;
  }
}

.deliveryButton {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: -1.5rem;
  margin-top: 1.5rem;
  width: calc(100% + 3rem);
}

.tradableContainer {
  align-items: center;
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 1rem;

  span {
    color: $primary-beige;
    letter-spacing: 0.04063rem;
    @include body-s;
  }
}

.toggleContainer {
  align-items: center;
  display: flex;

  .info {
    align-items: center;
    border-radius: 0.5rem;
    background-color: rgba(250, 250, 250, 0.05);
    display: flex;
    justify-content: center;
    margin-right: 0.75rem;
    padding: 0.5rem;
  }
}

.buttonContainer {
  background: $brand-blue-alpha-15;
  bottom: 0;
  padding: 1rem;
  width: 28.25rem;
  margin-left: -1rem;
  position: fixed;
  z-index: 12;


  @media (max-width: 960px) {
    width: calc(100% - 2rem);
  }

  .button {
    padding: 1.5rem 2.5rem;
    text-transform: uppercase;
  }
}

.help {
  color: $neutral-white-alpha-50;
  @include body-xs;
  padding: 1rem 0;
  margin-bottom: 5rem;
  text-align: center;
  text-transform: uppercase;

  a {
    color: $neutral-white-alpha-50;
  }

  @media (max-width: 960px) {
    margin-bottom: 5rem;
  }
}

.modalContainer {
  @media (max-width: 960px) {
    overflow: hidden;
  }
}

.modal {
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.4);
  border-radius: 1rem;
  border: 0.5px solid $brand-blue;
  background-color: $brand-dark-blue;
  padding: 2rem;

  @media (max-width: 960px) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    height: calc(100vh - 10rem);
    margin-top: 6.125rem;
    overflow: hidden;
    width: calc(100% - 4rem);
  }

  .close {
    display: block;
    margin-left: auto;
  }

  h1 {
    color: $primary-beige;
    text-shadow: 0px 0px 100px rgba(133, 213, 255, 0.5);
    font-family: Sequel100Black-75;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 2.25rem */
    text-transform: uppercase;

    @media (max-width: 960px) {
      font-size: 2rem;
    }
  }

  .modalContent {
    border-radius: 0.5rem;
    background-color: $brand-darker-blue;
    border: 0.5px solid $brand-blue-alpha-15;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding: 1rem;

    a {
      color: $white;
    }
  }
}

.tagline {
  color: $neutral-white-alpha-50;
  @include body-s-light;
  margin-top: -1rem;

  .xp {
    color: $brand-blue;
    font-family: Roboto Mono;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .solana {
    background: var(
      --Brands-Solana,
      linear-gradient(
        45deg,
        #9945ff 10.43%,
        #8752f3 30.85%,
        #5497d5 49.4%,
        #43b4ca 58.68%,
        #28e0b9 69.82%,
        #19fb9b 93.02%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include body-s-bold;
  }
}

.buttonRow {
  display: flex;
  button {
    text-transform: uppercase;

    @media (min-width: 960px) {
      &:first-of-type {
        margin-right: 1rem;
        width: 30%;
      }

      &:last-of-type {
        width: calc(70% - 1rem);
      }
    }
  }

  @media (max-width: 960px) {
    flex-direction: column-reverse;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
