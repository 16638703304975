@import "../../css/global.scss";

.headerRight {
  display: flex;
  align-items: center;

  :global(.wallet-adapter-button-trigger),
  .phoneButton,
  .accountButton {
    @include body-s-bold;
    align-items: center;
    background: unset !important;
    background-color: unset !important;
    box-shadow: unset;
    border-radius: 0 8px 8px 0 !important;
    border: 0.5px solid transparent;
    border-left: 0.5px solid $gray-secondary;
    display: flex;
    font-family: Roboto Mono !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    height: 41px;
    padding: 0 0.5rem;

    svg {
      path {
        fill: $secondary-blue;
      }
    }

    &:hover {
      @include stroke-border;
      border-radius: 0 8px 8px 0;
    }

    @media (max-width: 768px) {
      min-width: unset;
      padding: 0.5rem 1rem;
    }
  }
}

.headerLeft {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }

  img {
    margin-right: 1.5rem;
  }
}

.phoneButton {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    @include stroke-border;
    border-radius: 0 8px 8px 0;
  }
}

.blackHeader {
  align-items: center;
  background: $neutral-black-alpha-50;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2rem);
  margin: 0 auto;
  max-height: calc(100px - 3rem);
  padding: 1.5rem 3.75rem;
  position: fixed;
  top: 2rem;
  width: calc(100% - 7.5rem);
  z-index: 1000;

  @media (max-width: 1400px) {
    padding: 1.5rem;
    width: calc(100% - 3rem);
  }

  @media (max-width: 960px) {
    padding: 0.5rem 1.5rem;
    top: 29px;
  }

  @media (max-width: 530px) {
    background-color: $neutral-black;
    top: 35px;
    width: calc(100% - 3rem);
  }
}

.headerInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  margin: 0 auto;
  width: 100%;
}

.headerLink {
  align-items: center;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  color: $primary-beige;
  cursor: pointer;
  display: flex;
  @include body-s-light;
  height: 43px;
  margin-right: 2rem;
  padding: 0.5rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.1s ease-in all;
  z-index: 1;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 0.25rem;

    path {
      fill: #f5f6f6;
    }
  }

  &:hover {
    @include body-s-bold;
    color: $brand-blue;
    text-shadow: 0px 0px 10px 0px $brand-blue;
  }

  @media (max-width: 960px) {
    display: none;
  }
}

.discord {
  svg {
    margin-left: 0.5rem;
  }

  @media (max-width: 960px) {
    display: none;
  }
}

.headerLogo {
  width: 5.25rem;
  z-index: 1;

  @media (max-width: 960px) {
    width: 5rem;
  }
}

.selected,
.selected:hover {
  color: $secondary-gray-white;
  border: unset;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: $secondary-gray-white;
    bottom: 0px;
    left: 0;
  }
}

.signInButton {
  border-radius: 4px;
  font-family: Sequel100Black-55;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.loggedInButtonContainer {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  position: relative;
  z-index: 1;

  button {
    margin-left: 1rem;
  }

  @media (max-width: 960px) {
    display: flex;
    flex-wrap: wrap;

    button {
      margin-left: 0;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }
}

.tickets,
.wallet {
  width: 16px;
  height: 16px;
  path {
    fill: $secondary-blue;
  }
}

.ticketCount {
  align-items: center;
  border: 0.5px solid transparent;
  color: $white;
  display: flex;
  height: 41px;
  justify-content: center;
  padding: 0 0.5rem;
  text-decoration: none;
  position: relative;
  z-index: 1;
  @include body-s-bold;

  svg {
    margin-right: 0.5rem;

    path {
      fill: $primary-yellow;
    }
  }

  &:hover {
    border-radius: 8px 0 0 8px;
    border: 0.5px solid $glass-outside-stroke;
  }
}

.walletLoading {
  width: 17px;
  height: 17px;
}

:global(.sticky) {
  position: fixed;
  top: 0;
  width: calc(100% - 2rem);
}

.xpCash {
  align-items: center;
  color: $white;
  cursor: default;
  border-left: 1px solid #8d8d8d;
  display: flex;
  height: 41px;
  justify-content: center;
  padding: 0 0.5rem;
  text-decoration: none;
  position: relative;
  z-index: 1;
  @include body-s-bold;

  img,
  svg {
    margin-right: 0.5rem;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.walletIcon {
  text-transform: unset;
}

.mobileNavClosed {
  .search,
  .hamburger {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    &:first-of-type {
      margin-right: 1rem;
    }

    path {
      fill: $primary-beige;
    }
  }

  .hamburger {
    margin-right: 0 !important;
  }
}

.mobileNavContainer {
  position: fixed;
  top: 6.5rem;
  right: 0;
  width: calc(100% - 2rem);
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateY(-150%);
  transition: 0.3s ease-in-out all;
  padding: 1rem 1rem;
  overflow: hidden;

  .close {
    margin-bottom: 2rem;
  }

  .loggedInButtonContainer {
    margin-bottom: 1rem;
    width: fit-content;
  }

  .signInButton {
    margin-bottom: 1rem;
  }

  .mobileHeaderLink {
    align-items: center;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    color: $primary-beige;
    cursor: pointer;
    display: flex;
    @include body-s-light;
    margin-right: 2rem;
    padding: 0.5rem;
    opacity: 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.1s ease-in all;
    z-index: 1;

    &:hover {
      color: $primary-yellow;
      text-shadow: 1px 0px 4px $primary-yellow;
    }

    svg {
      height: 18px;
      width: 18px;
      margin-right: 0.25rem;

      path {
        fill: #f5f6f6;
      }
    }
  }

  .discord {
    svg {
      margin-left: 0.5rem;
    }
  }

  .socials {
    display: flex;
    margin-top: 2rem;

    img {
      width: 38px;
      height: 38px;
    }
  }
}

.mobileNavContainerOpen {
  background: $brand-dark-blue;
  opacity: 1;
  transform: translateY(0);
}

.listMenu {
  background-color: $brand-dark-blue;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(25px);
  border-radius: 1rem;
  border: 1px solid $brand-blue;
  position: absolute;
  top: 4.5rem;
  right: 3.75rem;
  width: fit-content;
  min-width: 45rem;

  h2 {
    color: $primary-beige;
    @include header-s;
    padding: 1.5rem 1rem 0 1rem;
    margin: 0;
  }

  .listMenuItem {
    color: $primary-beige;
    cursor: pointer;
    @include body-s-light;
    margin-right: 3rem;
    margin-bottom: 0.5rem;

    &:hover {
      color: $brand-blue;
    }
  }

  .list {
    height: 20rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
  }

  .listButtonContainer {
    background-color: $brand-blue-alpha-15;
    padding: 1rem;

    button {
      text-transform: uppercase;
    }
  }
}

// nfl
.listMenuOffset3 {
  width: 53rem;
}

.userMenu {
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid $brand-blue;
  background-color: $brand-dark-blue;
  backdrop-filter: blur(12.5px);
  color: $brand-blue;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include header-buttons;
  min-width: 10rem;
  padding: 1rem;
  position: relative;
  text-transform: uppercase;
  text-wrap: nowrap;

  @media (max-width: 960px) {
    width: calc(100% - 2rem);
  }

  svg {
    margin-right: 1rem;
  }
}

.userMenuContainer {
  border-radius: 0.5rem;
  top: 4.5rem;
  right: 3.75rem;
}

.menuContainerOpen {
  border-radius: 1rem;
  border: 0.5px solid $brand-blue;
  background-color: $brand-dark-blue;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.4);
  padding: 0.5rem;
  margin-top: 15rem;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-bottom: -0.5rem;
}

.usdcButton {
  border-radius: 0.25rem;
  background-color: $usdc-blue;
  border: 0.5px solid transparent;
  color: $primary-beige;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.04063rem;
  margin-top: 0.5rem;
  padding: 1rem;
  text-transform: uppercase;

  &:hover {
    border: 0.5px solid $brand-blue;
  }

  @include body-s-light;

  .usdcBalance {
    @include body-m-bold;
  }
}

.menuButton {
  align-items: center;
  border-radius: 0.25rem;
  color: $primary-beige;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.04063rem;
  margin-top: 0.5rem;
  padding: 1rem;
  text-transform: uppercase;

  @include body-s-light;

  &:hover {
    background-color: $brand-blue-alpha-15;
  }

  .count {
    align-items: center;
    display: flex;

    .number {
      background-color: $brand-blue;
      color: $neutral-black;
      padding: 0.25rem 0.5rem;
      border-radius: 6rem;
      margin-right: 0.5rem;
      @include body-s-bold;
    }
  }
}

.logOut {
  color: $system-red-red;
  &:hover {
    background-color: $system-red-alpha-15;
  }
}

.userMenuMobile {
  width: calc(100% - 2rem); 
}

.loggedOutInfo {
  align-items: center;
  color: $neutral-white-alpha-50;
  display: flex;
  justify-content: center;
  flex-direction: column;
  letter-spacing: 0.04063rem;
  padding: 6rem;
  text-align: center;
  text-transform: uppercase;

  @include body-s-light;

  svg {
    margin-bottom: 1rem;
  }
}

.disclaimerBanner {
  background-color: $brand-darker-blue;
  color: $primary-beige;
  font-size: 1rem;
  @include header-xxs;
  font-family: Roboto Mono;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0375rem;
  text-transform: uppercase;
  text-align: center;
  position: fixed;
  padding: 0.5rem 0;
  width: 100%;
  text-wrap: pretty;
  z-index: 300;

  @media (max-width: 960px) {
    align-items: center;
    justify-content: center;
    display: flex;
    height: calc(35px - 1rem);
    font-size: 0.6rem;
  }
}