@import "../css//global.scss";

.signInModal {
  background: $brand-dark-blue;
  border: 0.5px solid $brand-blue;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.40);
  border-radius: 8px;
  padding: 2rem;

  @media (max-width: 960px) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    height: calc(100vh - 10rem);
    margin-top: 6.125rem;
    margin-left: -1rem;
    overflow: hidden;
    width: calc(100% - 2rem);
  }


  h1 {
    color: $primary-beige !important;
    @include header-xs ;
    font-family: Sequel100Black-75;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 2.25rem */
    text-transform: uppercase !important;
    text-shadow: 0px 0px 100px rgba(133, 213, 255, 0.50);
    margin: 0;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  p {
    color: $neutral-gray !important;
    @include body-s;
    margin: 0;
    margin-bottom: 2rem;
  }
}

.modalContainer {
  @media (max-width: 960px) {
    overflow: hidden;
  }
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 40px;
  }
  svg {
    cursor: pointer;
    rect {
      fill: rgba(255, 255, 255, 0.15);
    }
  }
}

.modalButton {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 0;
  text-transform: uppercase;
}

.modalLink {
  align-items: center;
  color: $secondary-gray-white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include body-xs;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  text-decoration: none;

  svg {
    margin-left: 0.5rem;
  }
}

:global(.wallet-adapter-button-trigger) {
  align-items: center;
  background-color: unset !important;
  background: $brand-blue-alpha-15 !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border-radius: 8px !important;
  border: 0.5px solid transparent;
  box-sizing: border-box;
  color: $brand-blue !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  @include header-buttons;
  font-size: 0.75rem !important;
  font-family: Sequel100Black-65 !important;
  font-weight: 400 !important;
  height: 41px !important;
  line-height: unset !important;
  padding: 1rem;
  text-align: center !important;
  text-shadow: 0px 0px 50px 4px rgba(67, 186, 125, 0.5);
  text-transform: uppercase !important;
  transition: 0.3s all;
  width: 100% !important;
  z-index: 1;

  position: relative;

  &:hover {
    border: 0.5px solid $brand-blue;
  }

  svg:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  :global(.wallet-adapter-button-start-icon) {
    display: none;
  }

  div {
    display: flex;
  }
}

:global(.wallet-adapter-dropdown) {
  display: block !important;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  svg {
    cursor: pointer;
    rect {
      fill: rgba(255, 255, 255, 0.15);
    }
  }
}

.label {
  align-items: center;
  color: $neutral-gray;
  display: flex;
  @include body-s;
  letter-spacing: 0.04063rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  svg {
    margin-right: 0.25rem;
  }
}

.input {
  background: $neutral-input-box;
  color: $neutral-gray;
  @include body-s-bold;
  margin-bottom: 0.75rem;
  position: relative;
  width: 100%;
  z-index: 1;
}

.verificationInputContainer {
  display: flex;
  justify-content: space-between;
}

.verificationInput {
  box-sizing: border-box;
  text-align: center;
  @include body-xl-bold;
  width: 22%;
}

.fieldSuccess {
  border: 0.5px solid $system-green-green;
}

.verificationButton {
  margin-top: 1rem;
  text-transform: uppercase;
}

.dividerContainer {
  align-items: center;
  display: flex;
  text-transform: uppercase;

  .divider {
    height: 1px;
    background-color: $neutral-white-alpha-50;
    width: 100%;
  }

  .or {
    color: $neutral-white-alpha-50;
    @include body-s-light;
    margin: 0;
    padding: 1.5rem;
  }
}

.angleLeft {
  align-items: center;
  border-radius: 2.5rem;
  background-color: $brand-blue-alpha-15;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  padding: 0.25rem 0.4rem;

  svg {
    height: 1rem;
    width: 1rem;

    path {
      fill: $brand-blue;
    }
  }
}