@import "../css/global.scss";

.modal {
  border-radius: 0.75rem;
  border: 1px solid $neutral-white-alpha-15;
  background-color: $brand-dark-blue;
  padding: 0 1rem 1.5rem 1rem;
  position: absolute;
  top: 3.25rem;
  z-index: 10;

  .close {
    top: 1.5rem;
    right: 1rem;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
  }

  h1 {
    color: $primary-beige;
    @include sequel-header-l;
    margin: 0;
    text-shadow: 0px 0px 100px rgba(133, 213, 255, 0.5);
    text-align: center;
  }
}

.buttonRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .reset {
    color: $neutral-white-alpha-30;
    cursor: pointer;
    text-align: center;
    font-family: "Roboto Mono";
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03125rem;
    text-decoration-line: underline;
    text-transform: uppercase;
  }
}
