@import "../../css/global.scss";

.container {
  border-radius: var(--spacing-8, 8px);
  background: rgba(39, 117, 202, 0.40);
  width: 100%;
  box-sizing:border-box;
  padding: 16px;
}

.topContainer {
  display:flex;
  flex-direction:column;
  align-items:center;
}

.topContainerTopText{
  color: var(--brand-beige, #FDFCEA);
  /* Header/XXS */
  font-family: Sequel100Black-55;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  text-transform: uppercase;
  margin-top:16px;
  margin-bottom:8px;
}

.topContainerBottomText{
  color: var(--white-white, #FAFAFA);
  text-align: center;
  /* Body/Light/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.usdc {
  width:40px;
  height:40px;
}

.actionsContainer {
  border-radius: 0.5rem;
  background: rgba(250, 250, 250, 0.05);
  width:100%;
  padding: 16px;
  box-sizing:border-box;
  color: var(--brand-beige, #FDFCEA);
  font-family: Sequel100Black-55;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top:16px;
  margin-bottom: 1rem;
}

.action {
  display:flex;
  align-items:center;
  justify-content:space-between;
  height:65px;
}

.actionBar {
  background: rgba(250, 250, 250, 0.15);
  height:0.5px;
  width:100%;
  margin-top:8px;
  margin-bottom:8px;
}

.coinbasePayButton {
  border-radius: 0.25rem;
  height:41px;
  cursor:pointer;
  width: 9.125rem;
  object-fit: cover;
}

.bottomBarLeft{
  display:flex;
  flex-direction:column;
}

.comingSoon { 
  margin-bottom:8px;
  color: var(--white-alpha-50, rgba(250, 250, 250, 0.50));
  /* Body/Light/XS */
  font-family: Roboto Mono;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.demoButton {
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-24, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-12, 12px);
  border-radius: 4px;
  background: var(--brand-beige, #FDFCEA);
  backdrop-filter: blur(5px);
  color: var(--black-black, #181A1E);
  /* Body/Bold/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
}

.notReady {
  pointer-events:none;
  opacity:0.3;
}

.content {
  div {
    color: $primary-beige;
    @include body-s;
    letter-spacing: 0.04063rem;
    text-transform: uppercase;
  }
  p {
    @include body-xs-light;
    margin: 0;
    margin-top: 0.5rem;
  }

}