@import "../../css/global.scss";

.search {
  background: $brand-dark-blue-alpha-15;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  color: $brand-blue;
  border: 1px solid transparent;
  @include body-s-light;
  padding: 0.75rem 0.75rem 0.75rem calc(1rem + 21px);
  margin-left: -30px;
  width: calc(100% - 1rem);
  transition: 0.1s all;
  text-transform: uppercase;

  &::placeholder {
    color: $brand-blue;
  }

  &:focus {
    outline: none;
  }
}

.searchContainer {
  --angle: 0deg;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid $brand-blue;
  display: flex;
  position: relative;
  padding: 2px;
  position: relative;
  width: 430px;
  z-index: 1;

  svg {
    margin-left: 0.5rem;
    z-index: 1;
    path {
      fill: $brand-blue;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    margin-right: 1rem;
  }
}

.isMobile {
  display: flex;
  width: 100%;
  @media (min-width: 960px) {
    display: none;
  }

  .autocomplete {
    width: 100%;
    max-height: calc(100vh - 15rem);
    overflow: scroll;
  }
}

.inHeader {
  @media (max-width: 1400px) {
    max-width: 20rem;
  }
  @media (max-width: 960px) {
    width: 12rem;
    position: unset;

    .loadingContainer {
      left: 0;
      width: 100%;
    }
  }
}

.autocomplete {
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(25px);
  border: 0.5px solid $neutral-white-alpha-50;
  position: absolute;
  top: 3rem;
  width: 430px;
  max-height: 85vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 960px) {
    left: 0;
    width: 100%;
  }
}

.autocompleteInfo {
  align-items: center;
  display: flex;
}

.autocompleteItem {
  align-items: center;
  background-color: $brand-dark-blue;
  backdrop-filter: blur(25px);
  border-bottom: 0.5px solid $neutral-white-alpha-50;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  transition: 0.1s all;

  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  &:hover {
    background-color: $brand-dark-blue-alpha-15;

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
}

.autocompleteIconContainer {
  align-items: center;
  border-radius: 8px;
  background-color: $neutral-white-alpha-15;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  margin: auto 1rem auto 0;
  svg {
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    margin: auto !important;
    path {
      fill: $white;
    }
  }

  img {
    border-radius: 8px;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
    margin: 0 !important;
  }
}

.autocompleteItemLabel {
  color: $neutral-gray;
  display: flex;
  @include body-xs-light;

  svg {
    path {
      fill: $neutral-white-alpha-50;
    }
  }

  @media (max-width: 960px) {
    span {
      display: none;
    }
  }
}

.autocompleteItemTitle {
  color: $white;
  @include body-s-bold;
  margin: 0;
}

.angle {
  path {
    fill: $neutral-white-alpha-50;
  }
}

.results {
  align-items: center;
  background-color: $brand-darker-blue;
  border-radius: 0 0 8px 8px;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include body-xs-light;
  padding: 0.5rem;

  svg {
    height: 0.75rem;
    width: 1rem;
    margin-left: 0.5rem !important;

    path {
      fill: $neutral-white-alpha-50;
    }
  }
}

.withButton {
  width: 37rem;

  svg {
    margin-left: 1rem;
  }

  .search {
    padding: 1.5rem 1.5rem 1.5rem calc(1.5rem + 21px);
  }

  button {
    margin-right: 1.5rem;
    text-transform: uppercase;
  }

  .autocomplete,
  .loadingContainer {
    width: 37rem;
    top: 4.5rem;

    .autocompleteLoading {
      width: 37rem;
    }
  }
}

.loadingContainer {
  border: 0.5px solid $neutral-white-alpha-15;
  border-radius: 8px;
  position: absolute;
  top: 3rem;
  width: 100%;
}

.autocompleteLoading {
  display: flex;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: $brand-dark-blue;
  border-bottom: 0.5px solid $neutral-white-alpha-15;
  justify-content: space-between;

  .loadingLeft {
    display: flex;
    padding: 1rem;

    .loadingImage {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }

    div {
      display: flex;
      flex-direction: column;
    }

    .loadingTitle {
      width: 10rem !important;
      height: 1.125rem !important;
      object-fit: unset !important;
      margin-bottom: 0.25rem !important;
    }

    .loadingSubtitle {
      width: 8.375rem !important;
      height: 1.125rem !important;
      object-fit: unset !important;
      margin-bottom: 0;
    }
  }
  .loadingDescription {
    width: 2.5rem;
    height: 1.125rem;
    margin-top: 1rem;
    margin-right: 1.5rem;
    object-fit: unset;
  }
}

.autocompleteSection {
  .autocompleteSectionTitle {
    background-color: rgba(250, 250, 250, 0.05);
    color: $primary-beige;
    @include header-xs;
    font-size: 0.75rem;
    padding: 0.75rem;
    text-transform: uppercase;
  }
}

.dateContainer {
  color: $primary-beige;
  display: flex;
  flex-direction: column;

  .month {
    text-align: center;
    font-family: Sequel100Black-65;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .day {
    @include header-m;
    font-size: 1rem;
  }
}
