@import "../../css/global.scss";

.container {
  background: $neutral-black;
  border-radius: 1rem;
  padding: 1.5rem;
  width: 800px;
  margin: 1.5rem auto 3rem auto;

  h1 {
    @include header-s;
    color: $primary-beige;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    color: $white;
    @include body-xs-light;
  }

  @media (max-width: 960px) {
    padding-top: 0;
    width: 100%;
  }
}

.contactInfoContainer {
  background: $neutral-black;
  border-radius: 0.5rem;
  border: 0.5px solid $neutral-white-alpha-15;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  padding: 1.5rem;

  h2 {
    margin: 0;
    @include header-buttons;
    color: $primary-beige;
  }

  p {
    @include body-s-light;
    color: $neutral-gray;
  }
}

.inputRow {
  display: flex;
  justify-content: space-between;

  .inputContainer {
    width: calc(50% - 1rem);

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  .inputLabel {
    @include body-xs-light;
    color: $white;
    margin-bottom: 0.5rem;
  }
}

.step1,
.step2,
.step3 {
  align-items: center;
  background-color: $grays-card-background;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 3rem;

  h2 {
    color: $primary-beige;
    @include header-xxs;
    margin-top: 1.5rem;
  }
  p {
    color: $white;
    @include body-s-light;
    margin-bottom: 1.5rem;

    a {
      color: $white;
    }
  }
}

.usdcInput {
  align-items: center;
  border-bottom: 1px solid $primary-beige;
  display: flex;
  justify-content: center;
  color: $primary-beige;
  @include header-xl;
  margin-bottom: 1rem;
  width: 18rem;
  text-align: center;

  input {
    color: $primary-beige;
    border: unset;
    @include header-xl;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
    width: 100%;

    &::placeholder {
      @include header-xl;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus,
    &:active,
    &:hover {
      border: unset;
    }
  }
}

.walletInfo {
  display: flex;
  margin-bottom: 1.5rem;

  p {
    margin: 0;
    margin-left: 0.5rem;
  }
}

.step3 {
    padding: 1.5rem;
}

.progressBar {
    border-bottom: 1px solid $neutral-gray;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;

  .step {
    color: $neutral-white-alpha-50;
    display: flex;
    flex-direction: column;
    margin-bottom: -6px;
    svg {
        margin-top: 0.5rem;
        circle {
            fill: $neutral-white-alpha-50;
        }
    }
  }

  .active {
    color: $primary-yellow;
    svg {
      circle {
        fill: $primary-yellow;
      }
    }
  }

  .complete {
    color: $system-green-green;
    svg {
        circle {
            fill: $system-green-green
        }
    }
  }

  .progress2, .progress3 {
    align-items: center;
  }

  .progress4 {
    align-items: flex-end;
  }


}


.walletLoading {
    width: 5rem;
    height: 5rem;
    margin: 2.5rem 0;
}

.progressContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    h1 {
        color: $primary-beige;
        @include header-xxs;
    }

    .subtitle {
        color: $white;
        @include body-s-light;
        margin: 0.5rem auto;
        width: 80%;
    }

    .subtitleSmall {
        color: $neutral-white-alpha-50;
        @include body-xs-light;
        margin: 0.5rem auto;
        width: 80%;
    }
}

.transactionComplete {
    align-items: center;
    background-color: $system-green-alpha-15;
    border-radius: 0.5rem;
    padding: 8rem 5rem;
    display: flex;
    flex-direction: column;
    text-align: center;

    svg {
      width: 2.5rem;
      height: 2.5rem;
      margin-bottom: 1.5rem;
    }

    h2 {
      @include header-xxs;
      color: $primary-beige;
    }

    p {
      @include body-s-light;
      margin: 0;
    }
}

.balances {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;

  .usdcBalance {
    display: flex;
  }

  .usdcBalanceHeader {
    color: $neutral-white-alpha-50;
    @include header-xxs;
    margin-right: 0.5rem;
  }

  .usdcBalanceAmount {
    color: $primary-beige;
    @include header-xxs;
  }

  .balanceLoading {
    width: 1rem;
    height: 1rem;
  }
 }