@import "../css/global.scss";

.container {
  background-color: $neutral-black;
  position: relative;

  img {
    border-radius: 1rem 1rem 0 0;
    height: 15rem;
    object-fit: cover;
    width: 100%;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.cardInfo {
  padding: 1.5rem;

  h1 {
    border-bottom: 0.5px solid $neutral-white-alpha-15;
    color: $primary-beige;
    @include header-s;
    margin: 0.5rem 0;
    padding-bottom: 1rem;

    @media (max-width: 960px) {
      font-size: 0.8rem;
      margin: 0.25rem 0;
    }
  }
}

.date {
  color: $primary-yellow;
  font-family: Roboto Mono;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.venue {
  color: $primary-beige;
  display: flex;
  font-family: Sequel100Black-55;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  justify-content: space-between;
  line-height: 100%; /* 0.75rem */
  padding-top: 1rem;
}

.lineup {
  padding: 1rem 0;
  border-bottom: 0.5px solid $neutral-white-alpha-15;
  h2 {
    color: $neutral-white-alpha-50;
    font-family: Roboto Mono;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
