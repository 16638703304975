@import "../../css/global.scss";

.cardContainer {
  border: 2px solid transparent;
  border-radius: 1rem;
  position: relative;
  height: 20rem;
  transition: 0.1s all;
  width: 21rem;

  &:hover {
    border: 2px solid $brand-blue;
  }
}

.card {
  background-size: cover;
  background-position: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  border-radius: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  height: 20rem;
  width: 21rem;
  position: relative;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.cardImg {
  border-radius: 17px 17px 0 0;
  height: 184px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  position: relative;
}

.cardTop {
  background-color: $neutral-black-alpha-75;
  backdrop-filter: blur(12.5px);
  border-radius: 1rem 1rem 0 0;
  color: $grays-mains-gray;
  display: flex;
  justify-content: space-between;
  @include body-s;
  padding: 1rem 1.5rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s all;
}

.cardBottom {
  align-items: center;
  background-color: $neutral-black-alpha-75;
  backdrop-filter: blur(12.5px);
  border-radius: 0 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  position: absolute;
  bottom: 0;
  width: calc(100% - 3rem);

  h3 {
    color: $primary-beige;
    @include header-xxs;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    color: $grays-mains-gray;
    @include body-s-light;
    margin: 0;
    text-align: left;
    text-shadow: 0px 0px 50px 4px $system-green-alpha-50;
    span {
      margin: 0 0.25rem;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.priceContainer {
  align-items: flex-end;
  color: $neutral-gray;
  display: flex;
  flex-direction: column;

  span {
    @include body-s-light;
    // margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}

.priceDescription {
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-top: 0.5rem;
  margin-left: 0.5rem !important;
}

.price {
  color: $primary-beige;
  font-family: "Roboto Mono";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  margin-right: 0 !important;
}

.currencyIcon {
  align-items: center;
  border-left: 0.5px solid $neutral-white-alpha-30;
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: 0 0.25rem;

  &:last-of-type {
    padding-right: 0;
  }

  img,
  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  svg {
    padding: 0 0.25rem;
  }

  img {
    padding-left: 0.25rem;
  }
}

.angle {
  height: 12px;
  width: 12px;
  path {
    fill: $neutral-gray;
  }
}

.titleContainer {
  align-items: flex-start;
  width: calc(100% - 3rem);

  svg {
    padding-top: 3px;
    min-width: 12px;
    min-height: 12px;
    margin-left: 0.5rem;
    path {
      fill: $gray-secondary;
    }
  }
}

.dateTag {
  position: absolute;
  left: 1rem;
  bottom: 1rem;

  &:before {
    border: 2px solid transparent;
  }
}

.loader {
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/eventcardloader.gif);
  height: 306.5px;
  position: relative;
  top: 4.3rem;
}
