@import "../css/global.scss";

.pageContainer {
  display: flex;
  margin: 0 auto;
  padding-top: 1.5rem;
  width: 1100px;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.container {
  padding-top: 1.5rem;
  padding-left: 3rem;
  width: 800px;

  h1 {
    @include header-s;
    color: $primary-beige;
    margin: 0;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }

  @media (max-width: 960px) {
    padding-top: 0;
    padding-left: 0;
    width: 100%;
  }
}

.contactInfoContainer {
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0.5rem;
  border: 0.5px solid $neutral-white-alpha-15;
  backdrop-filter: blur(5px);
  padding: 1rem;

  h2 {
    margin: 0;
    @include header-xxs;
    color: $primary-beige;
    text-transform: uppercase;
  }

  p {
    @include body-xs-light;
    color: $neutral-gray;
    margin: 0;
    margin-top: 0.5rem;
  }
}

.contactInfoHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  button {
    @include body-xs-bold;
    height: 1.5rem;
    padding: 0.25rem 0.5rem;
  }

  @media (max-width: 960px) {
    margin-bottom: 1rem;
  }
}

.inputRow {
  display: flex;
  justify-content: space-between;

  .inputContainer {
    width: calc(50% - 1rem);

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  .inputLabel {
    @include body-xs-light;
    color: $white;
    letter-spacing: 0.03125rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}

.walletInfoContainer {
  border-radius: 1rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: rgba(250, 250, 250, 0.05);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
  margin-top: 1.5rem;

  h2 {
    @include header-xxs;
    color: $primary-beige;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  button {
    @include body-xs-bold;
    height: 1.5rem;
    padding: 0.25rem 0.5rem;
  }
}

.walletAddress {
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background-color: $brand-blue-alpha-15;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  color: $neutral-gray;
  @include body-s-light;

  button {
    border-radius: 0.25rem;
    @include body-xs;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
  }

  @media (max-width: 960px) {
    align-items: flex-start;
    word-break: break-all;

    button {
      word-break: auto-phrase;
    }
  }
}

.walletLoading {
  width: 17px;
  height: 17px;
}

.disconnectModal {
  background-color: $brand-dark-blue;
  border-radius: 1rem;
  border: 0.5px solid $brand-blue;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.40);
  padding: 1rem;

  h2 {
    color: $primary-beige;
    margin: 1rem 0;
    @include header-xs;
    text-transform: uppercase;
  }

  p {
    color: white;
    @include body-s-light;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .box {
    background-color: $brand-darker-blue;
    border: 0.5px solid $brand-blue-alpha-15;
    border-radius: 0.5rem;
    color: $white;
    margin-bottom: 2rem;
    padding: 1rem;
  }

  @media (max-width: 960px) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    height: calc(100vh - 98px - 2rem);
    margin-top: -3rem !important;
    margin-left: -1rem;
    width: 100%;
  }
}

.modalContainer {
  @media (max-width: 960px) {
    margin-top: 98px;
    overflow: hidden;
    margin-bottom: -98px;
  }
}

.disconnectModalHeader {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.disconnectModalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  button {

    width: calc(50% - 0.5rem);
    text-transform: uppercase;
  }
}
