@import '../css/global.scss';

.modalContainer {
    @media (max-width: 960px) {
      overflow: hidden;
    }
  }
  
.dispenserSuccessModal {
    border-radius: 1rem;
    border: 0.5px solid $brand-blue;
    background: $brand-dark-blue;
    box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.4);
    display: flex;
    flex-direction: column;
    padding: 2rem;

    @media (max-width: 960px) {
        border: unset;
        border-radius: unset;
        box-shadow: unset;
        height: calc(100vh - 10rem);
        margin-top: 6.125rem;
        margin-left: -1rem;
        overflow: hidden;
        width: calc(100% - 2rem);
      }

    .close {
        align-self: flex-end;
    }

    h1 {
        color: $primary-beige;
        @include header-xs;
    }

    p {
        color: $neutral-white-alpha-50;
        @include body-s-light;
        margin: 0;
    }

    .imageContainer {
        position:relative;
        margin-top: 1.5rem;

        img {
            border-radius: 0.5rem;
            margin-bottom: 0.5rem;
            width: 100%;
            max-height: unset;

            @media (max-width: 960px) {
                max-width: unset;
            }
        }
    }

    .discountAmount {
      color: $neutral-black;
        @include header-xl;
      position: absolute;
      text-align: center;
      top: calc(50% - 15px);
      width: 120px;
      left: calc(50% - 60px);
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buttonRow {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
        button {
            width: calc(50% - 0.5rem);
        }
    }
}

.discountDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.detail {
    border-radius: 0.25rem;
    border: 0.5px solid $brand-blue-alpha-15;
    background: $brand-darker-blue;
    color: $neutral-white-alpha-50;
    display: flex;
    flex-direction: column;
    @include body-xs-bold;
    padding: 0.5rem;
    width: calc(50% - 1.5rem);

    &:only-of-type {
        width: 100%;
    }
}