@import '../../css//global.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.5rem;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  border-radius: 34px;

  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E9E9E9;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::before {
    border-radius: 50%;
    position: absolute;
    content: "";
    height: 1.45rem;
    width: 1.45rem;
    left: 0rem;
    bottom: 0rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    z-index: 10;
  }
}

.sliderActive {
  background-color: $brand-blue;
  &::before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
}
