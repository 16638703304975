@import "../css/global.scss";

.artistPageContainer {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1.5rem;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0;
  }
}

.artistLeftColumn {
  margin-right: 1.5rem;

  @media (max-width: 960px) {
    margin-right: 0;
  }
}

.artistDescriptionContainer {
  border-radius: 8px;
  color: $white;
  height: fit-content;
  margin-bottom: 2rem;
  position: relative;
  width: 24.5rem;

  @media (max-width: 960px) {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .centerCropped {
    border-radius: 1rem;
    width: 24.5rem;
    height: 20rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 960px) {
      width: calc(100% + 2rem);
      margin-left: -1rem;
      margin-top: -2rem;
      border-radius: unset;
    }
  }

  h1 {
    bottom: 1rem;
    color: $primary-beige;
    left: 0.5rem;
    @include header-s;
    margin: 0;
    position: absolute;
    z-index: 10;
  }
}

.gradient {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 6.1%,
      rgba(0, 0, 0, 0.25) 50.62%,
      rgba(0, 0, 0, 0.3) 58.43%,
      rgba(0, 0, 0, 0.4) 68.98%,
      rgba(0, 0, 0, 0.5) 81.87%,
      rgba(0, 0, 0, 0.6) 93.19%,
      rgba(0, 0, 0, 0.7) 105.69%,
      rgba(0, 0, 0, 0.8) 117.46%,
      #000 124.84%
    );
  border-radius: 1rem;
  height: 20rem;
  position: absolute;
  width: 24.5rem;

  @media (max-width: 960px) {
    border-radius: unset;
    width: calc(100% + 2rem);
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.artistInfoContainer {
  padding: 1rem;

  h1 {
    color: $primary-beige;
    @include body-xl-bold;
    margin: 0;
  }

  p {
    border-bottom: 1px solid #3b3d41;
    color: $gray-secondary;
    font-family: "JKR Haas Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 1rem;
  }

  span {
    color: $ticketdex-purple;
    cursor: pointer;
    font-family: "JKR Haas Grotesk Bold";
  }
}

.linkContainer {
  cursor: pointer;
  @include body-s-bold;
  width: calc(50% - 0.5rem);
  a {
    align-items: center;
    border-radius: 0.25rem;
    border: 0.5px solid $brand-blue;
    background-color: $brand-dark-blue;
    backdrop-filter: blur(10px);
    color: $brand-blue;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    width: calc(100% - 2rem);

    &:hover {
      background: $brand-dark-blue-alpha-15;
    }

    @media (max-width: 960px) {
      @include body-xs-bold;
      padding: 0.75rem 0.5rem;
      width: calc(100% - 1rem);
    }

    button {
      width: 100%;
    }

    span {
      margin: 0;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.streamingLinks {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.artistEventList {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 960px) {
    padding: 1rem 0.5rem;
    width: calc(100% - 1rem);
  }

  h2 {
    color: $primary-beige;
    @include header-xs;
    margin-top: 0;
    margin-left: 1rem;
    text-transform: uppercase;

    @media (max-width: 960px) {
      margin-left: 0;
    }
  }

  h1 {
    color: $primary-beige;
    @include header-xs;
    margin-left: 1rem;
    text-transform: uppercase;

    span {
      color: $primary-yellow;
    }
  }
}

.priceDescription {
  display: flex;
  align-items: center;

  border-radius: 4px;
  box-sizing: border-box;
  transition: 0.3s all;

  @media (max-width: 960px) {
    padding: 0.5rem 0;
  }

  svg {
    margin: 0 0.25rem;
    width: 18px;
    height: 18px;
  }
}

.price {
  border-radius: 0.5rem;
  border: 0.5px solid $brand-blue;
  @include rm-all-caps-s;
  color: $brand-blue;
  padding: 1rem;
  text-align: center;
  text-wrap: nowrap;
  width: 7.5rem;

  .amt {
    @include rm-body-bold-m;
  }

  @media (max-width: 960px) {
    border: unset;
    color: $system-green-green;
    @include rm-all-caps-xs;
    padding: unset;
    width: unset;

    .amt {
      @include rm-all-caps-xs;
    }
  }
}

.discord {
  font-size: 0.8rem !important;
}

.currencyIcon {
  align-items: center;
  border-left: 0.5px solid $neutral-white-alpha-30;
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: 0 0.25rem;

  &:last-of-type {
    padding-right: 0;
  }

  img,
  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  svg {
    padding: 0 0.25rem;
  }

  img {
    padding-left: 0.25rem;
  }
}

.priceContainer {
  align-items: center;
  display: flex;
  margin-left: 1rem;

  @media (max-width: 960px) {
    margin-left: 0;
  }

  .arrow {
    width: 0.75rem;
    height: 0.75rem;
    path {
      fill: $primary-beige;
    }
  }
}

.eventListItem {
  color: $white;
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  text-decoration: unset;
  width: 100%;

  @media (max-width: 960px) {
    align-items: baseline;
    margin-bottom: 0;
    padding: 1rem 0;
  }

  h3 {
    color: $primary-beige;
    @include sequel-header-m;
    margin: 0;
  }

  .venueInfo {
    align-items: center;
    color: $white;
    display: flex;
    @include body-s-light;

    svg {
      margin-right: 0.5rem;
      path {
        fill: $white;
      }
    }
  }

  .venue {
    color: $neutral-white-alpha-50;
    @include rm-all-caps-s;
    margin: 0;
  }

  .eventInfo {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    width: 100%;

    @media (max-width: 960px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &:hover {
    background: $neutral-white-alpha-15;
    border-radius: 0.25rem;
    transition: 0.1s all;
  }
}

.eventRecommendations {
  color: $primary-beige;
  max-width: 1000px;
  margin: 3rem auto;

  h2 {
    @include header-xs;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
}

.card3w {
  width: calc((100% - 3rem) / 3);

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.recommendationsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.showMoreButton {
  @include header-buttons;
  margin: 1.5rem auto 3rem auto;
}

.loader {
  border-radius: $s;
  @include stroke-border;
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/eventcardloader.gif);
  height: 306.5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
}

.primary {
  color: $primary-yellow !important;
}

.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: -3rem;
  }

  h1 {
    @include header-s;
    text-align: center;
    margin: 1rem 0;

    @media (max-width: 960px) {
      @include header-xs;
    }
  }

  p {
    @include body-s-light;
    color: $white;
    text-align: center;
    margin: 0;

    @media (max-width: 960px) {
      @include body-xs-light;
    }
  }

  .explore {
    margin-top: 1.5rem;
  }
}

.filterContainer {
  display: flex;
  position: relative;
  margin-left: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-left: 0;
  }
}

.datePicker {
  position: absolute;
  z-index: 10;
  top: 2.5rem;
}

.filterButton {
  border-radius: 0.5rem;
  background-color: $brand-blue-alpha-15;
  border: 0.5px solid $neutral-white-alpha-15;
  backdrop-filter: blur(25px);
  color: $brand-blue;
  @include header-xxs;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;

    path {
      fill: $brand-blue;
    }
  }

  .close {
    position: relative;
    top: unset;
    right: unset;
  }
}

.locationMenuContainer {
  border-radius: 1.5rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background-color: $brand-dark-blue;
  position: absolute;
  top: 2.5rem;
  z-index: 10;

  @media (max-width: 960px) {
    top: 6rem;
  }
}

.locationItem {
  border-bottom: 0.5px solid $neutral-white-alpha-15;
  padding: 1rem 1.5rem;
  @include body-s-light;
  color: $white;
  cursor: pointer;
  &:hover {
    background-color: $neutral-input-box;
    color: #85d5ff;
  }
}

.locationItemSelected {
  @include body-s-bold;
  color: #85d5ff;
}

.compactEventCard {
  display: flex;
  margin-bottom: 2rem;
  width: calc(50% - 1rem);
  padding-right: 1rem;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 6.625rem;
    min-width: 6.625rem;
    height: 4.75rem;
    border-radius: 0.25rem;
    margin-right: 1.5rem;
    object-fit: cover;
  }

  .compactCardTitle {
    @include header-xxs;
    color: $primary-beige;
  }

  .compactCardVenue {
    @include body-s-light;
    color: $neutral-white-alpha-50;
    margin: 0.5rem 0;
  }

  .compactCardPrice {
    @include body-s-bold;
    color: $system-green-green;
  }
}
