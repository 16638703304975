@import "../css/global.scss";

.redeemContainer {
  background: url(https://hngr-icons.s3.amazonaws.com/ticketdex/crowdbg.png);
  height: 100vh;

  @media (max-width: 960px) {
    overflow: unset;
  }
}

.redeemContent {
  display: flex;
  justify-content: space-between;
  min-height: 85vh;
  height: fit-content;

  @media (max-width: 960px) {
    overflow: unset;
  }
}

.redeemRight {
  @media (max-width: 960px) {
    display: none;
  }

  img {
    height: calc(100vh - 97px - 32px);
    margin-right: 4rem;
  }
}

.redeemLeft {
  padding: 2rem 3.75rem;
  width: 50%;

  @media (max-width: 960px) {
    text-align: center;
    width: 100%;
    padding: 1rem;
  }

  h1 {
    color: $primary-beige;
    font-family: Sequel100Black-65;
    font-size: 2.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 2.875rem */
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    text-wrap: pretty-wrapping;

    @media (max-width: 960px) {
      @include header-s;
      margin-top: 1.5rem;
    }
  }

  p {
    color: $neutral-white-alpha-50;
    @include body-s-light;
    margin: 0;

    @media (max-width: 960px) {
      @include body-xs-light;
      text-wrap: pretty;
      text-align: center;
    }
  }

  a {
    color: $neutral-white-alpha-50;
  }
}

.discountLogo {
  width: 8rem;

  @media (max-width: 960px) {
    display: none;
  }
}

.mobileLogo {
  display: none;

  @media (max-width: 960px) {
    display: block;
    width: 8rem;
    margin: 0 auto;
  }
}

.redeemSteps {
  border-radius: 1rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: $neutral-black-alpha-50;
  backdrop-filter: blur(12.5px);
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  text-align: left;

  h2 {
    color: $neutral-white-alpha-50;
    @include header-xxs;
    margin: 0;
    padding-bottom: 1rem;
    border-bottom: 0.5px solid $neutral-white-alpha-15;
    margin-bottom: 1rem;
  }
}

.redeemStep {
  display: flex;
  margin-bottom: 1rem;

  .stepNum {
    color: $neutral-white-alpha-50;
    @include header-xs;
    margin-right: 1rem;
    width: 1.5rem;
  }

  .stepInstructions {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .stepText {
      color: $primary-beige;
      @include header-buttons;
      margin-right: 0.5rem;
      text-align: left;
    }

    .subtext {
      color: $neutral-white-alpha-50;
      @include body-xs-light;
      text-align: left;
    }
  }

  .longSubtext {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.inputContainer {
  align-items: center;
  display: flex;
  border-radius: 1rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: $neutral-black;
  box-shadow: 0px 0px 1.21138px 0px #fdfcea, 0px 0px 2.42276px 0px #fdfcea,
    0px 0px 8.47967px 0px #fdfcea, 0px 0px 16.95934px 0px #fdfcea,
    0px 0px 29.07315px 0px #fdfcea;
  padding: 1rem;
  margin-bottom: 1rem;

  input {
    width: 100%;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  button {
    height: 100%;
  }
  @media (max-width: 960px) {
    flex-direction: column;

    input {
      margin-right: 0;
      width: 100%;
    }
    button {
      margin-top: 1rem;
      width: 100%;
    }
  }
}

.loading {
  border-radius: 1rem;
  border: 0.5px solid $brand-blue;
  background: $brand-dark-blue;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.4);
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media (max-width: 960px) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    height: calc(100vh - 98px) !important;
    margin-top: 6.125rem;
    margin-left: -1rem;
    overflow: hidden;
    width: calc(100% - 4rem) !important;
  }

  .close {
    align-self: flex-end;
  }

  h1 {
    color: $primary-beige;
    text-shadow: 0px 0px 100px rgba(133, 213, 255, 0.5);
    font-family: Sequel100Black-75;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 2.25rem */
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  p {
    color: $neutral-white-alpha-50;
    @include body-s-light;
    margin-bottom: 2rem;
  }

  .loadingLeft {
    display: flex;
    flex-direction: column;

    @media (max-width: 960px) {
      width: 100%;
      padding: 0;
      padding-top: 1.5rem;
    }

    p {
      color: $neutral-white-alpha-50;
      @include body-s-light;
      margin-bottom: 2rem;
    }
  }

  .progressContainer {
    align-items: center;
    display: flex;

    .progressDot {
      circle {
        fill: $white;
      }
    }

    .progressDotActive {
      circle {
        fill: $system-green-green;
      }
    }

    .progressBar {
      height: 0.0625rem;
      min-width: 8rem;
      width: 45%;
      background-color: $white;
    }

    .progressBarActive {
      background-color: $system-green-green;
    }

    .progressLoading {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem;
    }
  }

  .progressText {
    color: $white;
    @include body-s-light;
    text-transform: uppercase;
    justify-content: space-between;
    display: flex;
    margin-top: 0.5rem;
  }
}

.loadingLogo {
  width: 8rem;
  height: 4rem;
  margin-left: -1rem;
}

.loadingH1 {
  color: $primary-beige;
  @include display-m;
  margin: 0;
  text-wrap: balance;

  @media (max-width: 960px) {
    @include display-s;
    font-size: 3rem;
  }
}

.modalContainer {
  @media (max-width: 960px) {
    overflow: hidden !important;
  }
}

.loadingGift {
  max-height: 15rem;
  margin-bottom: 1.5rem;
  object-fit: cover;

  @media (max-width: 960px) {
    width: 100%;
  }
}
