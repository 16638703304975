@import "../css/global.scss";

.container {
  background-position: cover;
  height: 100vh;
  overflow: hidden;
}

.back {
  color: $neutral-white-alpha-50;
  cursor: pointer;
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0rem; /* 0% */
  margin-bottom: 1rem;
  text-transform: uppercase;
  text-shadow: 0px 8px 50px rgba(133, 213, 255, 0.4);

  svg {
    margin-right: 0.5rem;
    path {
      fill: $neutral-white-alpha-50;
    }
  }

  @media (max-width: 960px) {
    margin-top: 0;
  }
}

.containerInner {
  display: flex;
  height: calc(100vh - 8.25rem);

  @media (max-width: 960px) {
    height: calc(100vh - 6.125rem);
    flex-direction: column;
    margin-left: -1rem;
    margin-top: -2rem;
    width: calc(100% + 2rem);
  }
}

.leftColumn {
  background-color: $brand-dark-blue;
  padding: 1rem;
  position: relative;
  width: 30.25rem;

  @media (max-width: 960px) {
    height: 100%;
    width: calc(100% - 2rem)
  }
}

.showInfo {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  img {
    border-radius: 0.5rem;
    object-fit: cover;
    height: 4.5rem;
    margin-right: 1rem;
    width: 4.5rem;
  }
}

.showInfoHeader {
  display: flex;
  width: 100%;
}

.showInfoContent {
  margin-top: auto;
  width: 100%;

  h1 {
    color: $primary-beige;
    @include header-s;
    margin: 0.5rem 0;
  }

  @media (max-width: 960px) {
    background: unset;
  }
}

.date {
  color: $brand-blue;
  @include body-s;
  letter-spacing: 0.04063rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.title {
  color: $primary-beige;
  @include header-xs;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.venue {
  align-items: center;
  color: $neutral-white-alpha-50;
  display: flex;
  font-family: Roboto Mono;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03438rem;
  text-transform: uppercase;

  svg {
    height: 1rem;
    margin-left: 1rem;
    width: 1rem;
    path {
      fill: $neutral-white-alpha-50;
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 0;
  }
}

.cardBottom {
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0 0 1rem 1rem;
  padding: 1rem;
}

.noCoupon {
  border-radius: 1rem;
}

.cardContent {
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.cardTitle {
  color: $neutral-white-alpha-50;
  @include body-s;
  text-transform: uppercase;
}

.total {
  color: $white;
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.04063rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
}

.taxDisclaimer {
  border-top: 0.5px solid $neutral-white-alpha-15;
  color: $neutral-white-alpha-50;
  padding-top: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  font-family: Roboto Mono;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
}

.ticketQuantityContainer {
  align-items: center;
  display: flex;

  span {
    color: $neutral-white-alpha-50;
    @include body-s;
    text-transform: uppercase;
    text-align: right;
    margin-right: 1rem;
    width: 5rem;
  }
}

.subtotal {
  color: $neutral-white-alpha-50;
  @include body-s;
  letter-spacing: 0.04063rem;
}

.price {
  @include header-xxs;
  color: $primary-beige;
  margin-right: 0.5rem;
}

.cardQuantitySelect {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s-light;
}

.rightColumn {
  width: 100%;
  max-width: calc(100% - 30.25rem);

  @media (max-width: 960px) {
    display: none;
  }
}

.seatInfoContainer {
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0.75rem;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.seatInfo {
  display: flex;
}

.section,
.row {
  color: $neutral-white-alpha-50;
  display: flex;
  flex-direction: column;
  @include body-s;
  text-transform: uppercase;

  .value {
    color: $primary-beige;
    @include header-xs;
    margin-top: 0.5rem;
  }
}

.divider {
  width: 1px;
  background-color: $neutral-white-alpha-50;
  margin: 0 1rem;
  transform: rotate(20deg);
}

.venueImage {
  border-radius: 8px 8px 0 0;
  height: 100%;
  position: relative;

  @media (max-width: 960px) {
    height: fit-content;
  }

  > div {
    > div {
      > div:last-of-type {
        > div {
          border: unset !important;
        }
      }
    }
  }
}

.couponContainer {
  background-color: $brand-blue-alpha-15;
  backdrop-filter: blur(12.5px);
  border-radius: 1rem 1rem 0 0;
  color: $primary-beige;
  @include body-xs-light;
  padding: 0.75rem 1rem;

  .couponHeader {
    color: $brand-blue;
    @include body-s;
    letter-spacing: 0.04063rem;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }
}

.help {
  color: $neutral-white-alpha-50;
  @include body-xs;
  padding: 1rem 0;
  text-align: center;
  text-transform: uppercase;

  a {
    color: $neutral-white-alpha-50;
  }
}

.buttonContainer {
  background: $brand-blue-alpha-15;
  bottom: 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  margin-left: -1rem;
  position: absolute;

  .button {
    padding: 1.5rem 2.5rem;
    text-transform: uppercase;
  }
}
