@import "../css/global.scss";

.topLevelContainer {
  background-position: cover;
  height: 100vh;
  overflow: hidden;
}

.container {
  border-top: 0.5px solid $neutral-white-alpha-15;
  color: $white;

  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.pageContainerOuter {
  display: flex;
  height: calc(100vh - 8.25rem);

  @media (max-width: 960px) {
    margin-top: -2rem;
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
}

.leftColumn {
  background-color: $brand-dark-blue;
  padding: 1rem;
  position: relative;
  overflow: scroll;
  width: 30.25rem;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: 960px) {
    height: 100%;
    width: calc(100% - 2rem);
  }
}

.back {
  color: $neutral-white-alpha-50;
  cursor: pointer;
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0rem; /* 0% */
  margin-bottom: 1rem;
  text-transform: uppercase;
  text-shadow: 0px 8px 50px rgba(133, 213, 255, 0.4);

  svg {
    margin-right: 0.5rem;
    path {
      fill: $neutral-white-alpha-50;
    }
  }

  @media (max-width: 960px) {
    margin-top: 0;
  }
}

.showInfo {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  img {
    border-radius: 0.5rem;
    object-fit: cover;
    height: 4.5rem;
    margin-right: 1rem;
    width: 4.5rem;
  }
}

.showInfoHeader {
  display: flex;
  width: 100%;
}

.showInfoContent {
  margin-top: auto;
  width: 100%;

  h1 {
    color: $primary-beige;
    @include header-s;
    margin: 0.5rem 0;
  }

  @media (max-width: 960px) {
    background: unset;
  }
}

.info {
  padding: 0.5rem 0.75rem;
}

.venueImage {
  background: #1d1b22;
  border: 1px solid #3b3d41;
  backdrop-filter: blur(12.3865px);
  border-radius: 6px;
  max-width: 77px;
  max-height: 58px;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.seatInfoContainer {
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0.75rem;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.seatInfo {
  display: flex;
}

.date {
  color: $brand-blue;
  @include body-s;
  letter-spacing: 0.04063rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.title {
  color: $primary-beige;
  @include header-xs;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.venue {
  align-items: center;
  color: $neutral-white-alpha-50;
  display: flex;
  font-family: Roboto Mono;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03438rem;
  text-transform: uppercase;

  svg {
    height: 1rem;
    margin-left: 1rem;
    width: 1rem;
    path {
      fill: $neutral-white-alpha-50;
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 0;
  }
}

.section,
.row {
  color: $neutral-white-alpha-50;
  display: flex;
  flex-direction: column;
  @include body-s;
  text-transform: uppercase;

  .value {
    color: $primary-beige;
    @include header-xs;
    margin-top: 0.5rem;
  }
}

.divider {
  width: 1px;
  background-color: $neutral-white-alpha-50;
  margin: 0 1rem;
  transform: rotate(20deg);
}

.cardContent {
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.subtotal {
  color: $neutral-white-alpha-50;
  @include body-s;
  letter-spacing: 0.04063rem;
}

.discount {
  color: $system-green-green !important;
  @include body-s;
  letter-spacing: 0.04063rem;
  margin-top: 0.5rem;
}

.price {
  @include header-xxs;
  color: $primary-beige;
  margin-right: 0.5rem;
}

.total {
  color: $white !important;
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0.5rem;
  letter-spacing: 0.04063rem;
  text-transform: uppercase;
}

.taxDisclaimer {
  border-top: 0.5px solid $neutral-white-alpha-15;
  color: $neutral-white-alpha-50;
  padding-top: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  font-family: Roboto Mono;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
}

.cardTitle {
  color: $neutral-white-alpha-50;
  @include body-s;
  text-transform: uppercase;
}

.cardBottom {
  background: rgba(250, 250, 250, 0.05);
  border-radius: 0 0 1rem 1rem;
  padding: 1rem;
}

.ticketQuantityContainer {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.05);
  border-radius: 0.25rem;
  color: $white;
  display: flex;
  font-family: Sequel100Black-65;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 1rem;
  padding: 0.25rem 0.5rem;

  svg {
    margin-right: 0.5rem;
    path {
      fill: $white;
    }
  }
}

.ticketInfoRight {
  align-items: center;
  display: flex;

  .ticketPrice {
    color: $grays-mains-gray;
    @include body-xs-light;
    span {
      color: $white;
      @include header-xxs;
      margin-right: 0.5rem;
    }
  }
}

.buttonContainer {
  background: $brand-blue-alpha-15;
  bottom: 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  margin-left: -1rem;
  position: fixed;
  width: 28.25rem;
  z-index: 12;

  @media (max-width: 960px) {
    width: calc(100% - 2rem);
  }

  button {
    padding: 1.5rem 2.5rem;
    text-transform: uppercase;
  }
}

.checkoutContainer {
  width: 100%;
  max-width: calc(100% - 30.25rem);

  @media (max-width: 960px) {
    display: none;
  }
}

.seatmap {
  border-radius: 8px 8px 0 0;
  height: 100%;
  position: relative;

  @media (max-width: 960px) {
    height: fit-content;
  }

  > div {
    > div {
      > div {
        > div:last-of-type {
          > div {
            border: unset !important;
          }
        }
      }
    }
  }
}

.paymentMethodBox {
  align-items: center;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: rgba(250, 250, 250, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  transition: 0.1s all;
  width: calc(50% - 8px);

  &:hover {
    background: $neutral-white-alpha-15;
  }
}

.paymentMethodBoxTop {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}

.paymentMethodBoxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.paymentMethodBoxBottomText {
  @include body-s-light;
  color: $primary-beige;
  text-transform: uppercase;
}

.creditCard,
.unfilledCircle {
  path {
    fill: $neutral-white-alpha-15;
  }
}

.paymentMethodBoxBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.savedCardsContainer {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
}

.addCardBox {
  align-items: center;
  border-radius: var(--spacing-4, 4px);
  border: 1px dashed var(--primary-beige, #fdfcea);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  @include body-xs-light;
  justify-content: center;
  padding: 0.75rem;
  width: calc(50% - 2rem);

  svg {
    margin-right: 0.5rem;
  }
}

.savedCardBox {
  border-radius: 0.5rem;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem;
  width: calc(50% - 2rem);

  &:hover {
    background-color: $neutral-white-alpha-15;
  }

  .savedCardLabel {
    align-items: center;
    display: flex;

    @include body-xs-bold;
    svg {
      margin-right: 0.5rem;
    }
  }
}

.savedCardBoxTopText {
  color: #fff;
  font-family: Roboto Mono;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.savedCardBoxNumber {
  color: var(--neutral-white, #fafafa);
  @include body-m-light;
}

.selectedPaymentMethodBorder {
  border: 1px solid $brand-blue;
  background-color: $brand-blue-alpha-15;

  .paymentMethodBoxBottomText {
    @include body-s-bold;
  }
}

.filledCircle {
  path {
    fill: $brand-blue;
  }
}

.paymentMethodBoxDisabled {
  opacity: 0.5;
}

.walletInfo {
  align-items: center;
  border-radius: 0.5rem;
  background-color: $brand-blue-alpha-15;
  color: $primary-beige;
  display: flex;
  justify-content: space-between;
  @include body-s;
  padding: 1rem;
  text-transform: uppercase;

  .walletBalance {
    color: $primary-beige;
    @include body-s-bold;
  }
}

:global(.StripeElement--empty) {
  border: 1px solid $brand-blue-alpha-15;
  border-radius: 0.5rem;
  background-color: $brand-blue-alpha-15;
  padding: 1rem;
  color: $white;
}

.help {
  color: $neutral-white-alpha-50;
  @include body-xs;
  padding: 1rem 0;
  margin-bottom: 5rem;
  text-align: center;
  text-transform: uppercase;

  a {
    color: $neutral-white-alpha-50;
  }
}

.stripeContainer {
  border-radius: 0.75rem;
  border: 0.5px solid $brand-blue;
  background-color: (250, 250, 250, 0.05);
  padding: 1rem;
}

.sufficientUSDC {
  border-radius: 0.75rem;
  border: 0.5px solid $brand-blue;
  background-color: (250, 250, 250, 0.05);
  padding: 0.5rem;
}

.saveWithUSDC {
  align-items: center;
  background-color: $system-green-alpha-15;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .saveWithUSDCHeader {
    color: $system-green-green;
    @include body-s;
    letter-spacing: 0.04063rem;
    margin-bottom: 0.5rem;
  }

  .saveWithUSDCSubheader {
    @include body-xs-light;
  }
}
