@import "../../css/global.scss";

.pageContainer {
  position: relative;
  background-position: top;
  background-size: contain;
  background-repeat: repeat-y;

  @media (min-width: 960px) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.pageBackground {
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Crowd3+1.png);
  background-position: top;
  background-size: contain;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.contentContainer {
  height: calc(100vh - 131px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 auto;
  padding-top: calc(80px + 1rem + 2rem);
  position: relative;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 1rem;
    padding-top: calc(80px + 1rem + 35px);
  }
}

.bgImage {
  width: 100vw;
  object-fit: contain;
  object-position: top;
  position: absolute;
  z-index: 0;

  @media (max-width: 960px) {
    top: 112px;
  }
}

.noScroll {
  overflow: hidden;
}