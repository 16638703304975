@import "../css/global.scss";

.unsealModalContainer {
  background: $brand-dark-blue;
  border-radius: 1rem;
  border: 0.5px solid $brand-blue;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.40);
  padding: 2rem;
  position: relative;

  @media (max-width: 960px) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    height: calc(100vh - 10rem);
    margin-top: 6.125rem;
    margin-left: -1rem;
    overflow: hidden;
    width: calc(100% - 2rem);
  }
}

.modalContainer {
  @media (max-width: 960px) {
    overflow: hidden;
  }
}


.modalNavigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.close, .angle {
  cursor: pointer;

}

.angleContainer {
  align-items: center;
  border-radius: 2.5rem;
  background-color: $brand-blue-alpha-15;
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 1rem;
    height: 1rem; 
    path {
      fill: $brand-blue;
    }
  }
}

.warningIcon {
  font-size: 40px;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
}

.unsealWarning {
  color: $primary-beige;
  text-shadow: 0px 0px 100px rgba(133, 213, 255, 0.50);
  font-family: Sequel100Black-75;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 2.25rem */
  text-transform: uppercase;  margin-bottom: 0.5rem;
}

.unsealImageContainer {
  border-radius: 0.5rem;
  border: 0.5px solid $neutral-white-alpha-15;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  h2 {
    color: $primary-beige;
    @include header-buttons;
    margin-bottom: 1.5rem;
  }

  img {
    margin: auto;
    width: 16.5rem;
    height: 13rem;
  }
}

.sendLink {
  color: $white;
  cursor: pointer;
  text-align: center;
  font-family: Roboto Mono;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 1.5rem 0;
  text-decoration-line: underline;
}

.unsealed {
  color: $system-green-green;
}

.subtext {
  color: $neutral-white-alpha-50;
  @include body-s-light;
  margin-bottom: 2rem;
}

.warningListContainer {
  background: $neutral-input-box;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 1rem 1rem 0.5rem 1rem;
  width: calc(100% - 2rem);

  @include stroke-border;
}

.warningListItem {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s;
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 100%;

  button {
    text-transform: uppercase;
    width: calc(50% - 0.5rem);
  }
}

.ticketFormRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  
    .ticketInputFieldContainer {
      width: calc(50% - 0.5rem);
    }
  }

  .ticketInputFieldContainer {
    input {
      width: 100%;
    }
  
    .label {
      @include body-xs-light;
      color: $white;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
  }