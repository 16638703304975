@import "../css/global.scss";

.container {
    border-right: 0.5px solid $neutral-white-alpha-15;
    min-width: 17.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    width: 17.5rem;

    @media (max-width: 960px) {
        display: none;
    }

    h1 {
        color: $primary-beige;
        @include header-xs;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    p {
        color: $neutral-white-alpha-50;
        border-bottom: 0.5px solid $neutral-white-alpha-15;
        @include body-s-light;
        margin-bottom: 1rem;
        padding-bottom: 1.5rem;
    }
}

.initials {
    background-color: $primary-beige;
    border-radius: 5rem;
    color: $neutral-black;
    @include header-s;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    width: fit-content;
}

.usdcButton {
    border-radius: 0.25rem;
    background-color: $usdc-blue;
    border: 0.5px solid transparent;
    color: $primary-beige;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.04063rem;
    margin-top: 0.5rem;
    padding: 1rem;
    text-transform: uppercase;
  
    &:hover {
      border: 0.5px solid $brand-blue;
    }
  
    @include body-s-light;
  
    .usdcBalance {
      @include body-m-bold;
    }
  }

  .menuButton {
    align-items: center;
    border-radius: 0.25rem;
    color: $primary-beige;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.04063rem;
    margin-top: 0.5rem;
    padding: 1rem;
    text-transform: uppercase;
  
    @include body-s-light;
  
    &:hover {
      background-color: $brand-blue-alpha-15;
    }
  
    .count {
      align-items: center;
      display: flex;
  
      .number {
        background-color: $brand-blue;
        color: $neutral-black;
        padding: 0.25rem 0.5rem;
        border-radius: 6rem;
        margin-right: 0.5rem;
        @include body-s-bold;
      }
    }
  }

  .logOut {
    color: $system-red-red;
    &:hover {
      background-color: $system-red-alpha-15;
    }
  }

  .active {
    background-color: $brand-blue-alpha-15;
    color: $brand-blue;
    border: 0.5px solid $brand-blue;

    svg {
      path {
        fill: $brand-blue;
      }
    }
  }