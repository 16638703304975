@import "../../css/global.scss";

.locationMenuItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.5rem;
  transition: 0.1s ease-in all;

  &:hover {
    border-radius: 0.25rem;
    background-color: $brand-blue-alpha-15;
  }

  span {
    @include body-m-light;
    color: $white;
  }
}

.selectedLocation {
  span {
    color: $brand-blue;
  }
  background-color: $brand-blue-alpha-15;
  svg {
    path {
      fill: $brand-blue;
    }
  }
}

.menuContainer {
  position: relative;
  margin-right: 1rem;

  @media (max-width: 960px) {
    position: unset;
    width:100%;
    box-sizing:border-box;
    margin-bottom: 8px;
    
    button {
      width:100%;
      justify-content:space-between;
      box-sizing:border-box;
    }
  }
}

.eventContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  max-width: 1100px;
  margin: 1.5rem auto;
  width: 100%;
}

.eventListCard {
  margin-bottom: 2rem;
  margin-right: 2.5rem;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @media (max-width: 960px) {
    margin-right: 0;
    width: 100%;
  }
}

.datePickerContainer {
  position: absolute;
  z-index: 2;
  top: 2.5rem;

  @media (max-width: 960px) {
    left: 0;
    top: 3rem;
  }
}

.showMoreButton {
  margin-top: 3rem;
  margin-bottom: 5rem;
  width: auto;
}

.emptyState {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;

  @media (max-width: 960px) {
    width: 100%;
  }

  h1 {
    @include header-s;
    color: $primary-beige;
    margin: 0;
    margin-top: -2rem;
  }

  p {
    @include body-s;
    color: $neutral-gray;
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.loadingStateContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;
  width: 1100px;

  img {
    border-radius: 1rem 1rem 0 0;
    @media (max-width: 960px) {
      width: 100%;
    }
  }
}

.eventTypeMenuContainer {
  border: 0.1px solid $neutral-white-alpha-15;
  border-radius: 0.75rem;
  background-color: $brand-dark-blue;
  padding: 1rem;
  position: absolute;
  top: 3.5rem;
  width: 25rem;
  z-index: 10;
  @include glow-brand-blue;

  .eventTypeMenuItem {
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    @include body-m-light;
    padding: 0.75rem;
    text-transform: capitalize;
    transition: 0.1s ease-in all;

    &:hover {
      border-radius: 0.25rem;
      background-color: $brand-blue-alpha-15;
    }
  }

  .selectedEventType {
    color: $brand-blue;
    background-color: $brand-blue-alpha-15;
  }

  @media (max-width: 960px) {
    width:100%;
    box-sizing:border-box;
  }

}

.locationMenuContainer {
  border-radius: 0.75rem;
  border: 1px solid $neutral-white-alpha-15;
  background-color: $brand-dark-blue;
  @include glow-brand-blue;
  position: absolute;
  padding: 1rem;
  top: 4rem;
  width: 25rem;
  z-index: 10;


  @media (max-width: 960px) {
    width:100%;
    box-sizing:border-box;
  }

  .inputContainer {
    align-items: center;
    display: flex;
    border: 1px solid $brand-blue;
    border-radius: 0.75rem;
    background-color: $brand-dark-blue;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    width: calc(100% - 2rem);

    svg {
      margin-right: 0.75rem;
      path {
        fill: $brand-blue;
      }
    }

    input {
      background-color: $brand-dark-blue;
      border: none;
      color: $white;
      @include body-s-light;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $brand-blue;
        @include body-s-light;
      }
    }
  }

  .locationMenuContent {
    border-top: 1px solid $neutral-white-alpha-15;
    padding-top: 1rem;

    button {
      @include body-s-light;
      text-transform: unset;
      padding: 0.75rem 1rem;
    }
  }
}

