@import "../../css/global.scss";

.filterContainer {
  background: $neutral-white-alpha-15;
  border-radius: 0.75rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(25px);
  display: flex;
  margin-left: 1rem;
  margin-bottom: 2.5rem;
  padding: 0.25rem;
  width: fit-content;

  @media (max-width: 960px) {
    margin-left: 0;
  }

  .option {
    border-radius: 0.5rem;
    border: 0.5px solid transparent;
    color: $neutral-gray;
    cursor: pointer;
    @include header-buttons;
    min-width: 6rem;
    padding: 0.5rem 1rem;
    text-align: center;
    transition: 0.1s all;
    text-transform: uppercase;

    &:hover {
      background: $brand-blue-alpha-15;
      color: $brand-blue;
    }

    @media (max-width: 960px) {
      min-width: unset;
    }
  }

  .selected {
    background: $brand-blue-alpha-15;
    border: 0.5px solid $brand-blue;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    color: $brand-blue;
  }
}

.fullWidth {
  margin-left: 0;
  width: 100%;

  .option {
    width: 50%;
  }
}