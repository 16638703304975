@import "../../css/global.scss";

.leftColumn {
  margin-right: 1rem;
  width: 26.125rem;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.showInfo {
  position: relative;
  padding: 0;

  img {
    border-radius: 1rem;
    border: 0.5px solid $neutral-white-alpha-15;
    height: 21rem;
    object-fit: cover;
    width: 100%;
  }
}

.showInfoHeader {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 6.1%,
    rgba(0, 0, 0, 0.25) 50.62%,
    rgba(0, 0, 0, 0.3) 58.43%,
    rgba(0, 0, 0, 0.4) 68.98%,
    rgba(0, 0, 0, 0.5) 81.87%,
    rgba(0, 0, 0, 0.6) 93.19%,
    rgba(0, 0, 0, 0.7) 105.69%,
    rgba(0, 0, 0, 0.8) 117.46%,
    #000 124.84%
  );
  display: flex;
  justify-content: space-between;
  height: 100%;

  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  button {
    margin-left: 1rem;
  }
}

.showInfoContent {
  margin-top: auto;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}
.date {
  color: var(--brand-yellow, #ffe400);
  font-family: Roboto Mono;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.title {
  color: $primary-beige;
  @include header-s;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.venue {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s-light;
  svg {
    height: 18px;
    margin-right: 0.5rem;
    min-height: 18px;
    min-width: 18px;
    width: 18px;
    path {
      fill: $secondary-gray-white;
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 0;
  }
}

.showImageContainer {
  background-color: black;
  border-radius: 8px 8px 0 0;
  min-height: 10rem;
  margin-top: 1rem;
  position: relative;

  img {
    height: 8.75rem;
    object-fit: cover;
    width: 100%;
  }

  // ew tevo pls fix this
  > div {
    height: 10rem;

    > div {
      > div {
        > div:last-of-type {
          > div {
            border: unset !important;
          }
        }
      }
    }
  }
}

.showImage {
  border-radius: 1rem 1rem 0 0;
  height: 8.75rem;
  object-fit: cover;
  width: 100%;
}

.ticketInfo {
  border-radius: 0 0 1rem 1rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: $neutral-black;
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
}

.ticketCount {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  padding: 1rem;
  width: fit-content;

  .ticketLabel {
    @include header-buttons;
    color: $neutral-white-alpha-50;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .ticketInformation {
    @include header-xxs;
    color: $primary-beige;
    text-align: center;
  }
}

.sectionCount {
  display: flex;
}

.sectionItem {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  text-align: center;

  .sectionLabel {
    @include header-buttons;
    color: $neutral-white-alpha-50;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .sectionInformation {
    @include header-xxs;
    color: $primary-beige;
    text-align: center;
  }
}

.ticketCount,
.sectionCount {
  border: 0.5px solid $neutral-white-alpha-15;
  border-radius: 0.5rem;
}

.priceInfo {
  border-radius: 1rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: $neutral-black;
  padding: 1rem;
}

.metadataRow {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.5rem 0;
  width: 100%;

  &:last-of-type {
    padding-bottom: 0;
    padding-top: 1rem;
  }
}

.metadataItemDetail {
  color: $white;
  @include body-s-light;
}

.metadataItemValue {
  align-items: center;
  color: $white;
  @include body-s-bold;
  display: flex;

  svg {
    margin-right: 0.5rem;
  }
}

.metadataLarge {
  @include body-s-bold;
}

.disclaimer {
  color: $secondary-gray-white;
  @include body-xs;
  margin-top: 0.5rem;
  text-align: center;

  a {
    color: $secondary-gray-white;
  }
}

.tax {
  border-top: 0.5px solid $neutral-white-alpha-15;
  @include body-s-light;
  color: $neutral-white-alpha-50;
  padding-top: 0.5rem;
}
