@import "../css/global.scss";

.pageContainer {
  display: flex;
  padding-top: 1.5rem;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 960px) {
    padding-top: 0;
  }
}

.container {
  margin-left: 2.5rem;
  width: 100%;

  @media (max-width: 960px) {
    margin-left: 0;
  }
}

.header {
  h1 {
    color: $primary-beige;
    @include header-s;
    text-transform: uppercase;

    @media (max-width: 960px) {
      @include header-xs;
    }
  }
}

.buttonHeaderRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;


  @media (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.filterButtonContainer {
  display: flex;

  button {
    align-items: center;
    display: flex;
    width: fit-content;
    margin-right: 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 1rem;
  }
}

.noUpcoming {
  align-items: center;
  border-radius: 8px;
  color: $white;
  display: flex;
  @include body-s;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 4rem 1rem;
  width: 100%;

  a {
    margin-top: 1rem;
    text-decoration: none;
  }

  p {
    @include header-xs;

    @media (max-width: 960px) {
      text-align: center;
    }
  }
}

.iconContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  display: flex;
  justify-content: center;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  width: 64px;
}

.nftContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem auto;

  @media (max-width: 960px) {
    width: unset;
  }
}

.loadingSubtext {
  @include body-s;
  text-align: center;
  color: $white;
}

.loadingContainer {
  color: $white;
  text-align: center;
  padding: 10rem 10rem 20rem 10rem;

  @media (max-width: 960px) {
    padding: 15rem 0;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  margin: auto;
}

.subHeader {
  color: $primary-beige;
  @include header-xs;
}