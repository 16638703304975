@import "../css/global.scss";

.cityHeroContainer {
  background-size: cover;
  width: calc(100% + 2rem);

  @media (max-width: 960px) {
    background-size: auto;
    background-repeat: no-repeat;
    margin-left: -1rem;
    margin-top: -1rem;
  }
}

.cityHero {
  padding: 4.5rem 3.75rem;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 1rem 1.5rem;
  }
}

.cityLogoImage {
  width: 9.125rem;
  height: 8.75rem;

  @media (max-width: 960px) {
    width: 6.125rem;
    height: 5.75rem;
  }
}

.experience {
  height: 4rem;
  width: 17rem;

  @media (max-width: 960px) {
    width: 12rem;
    height: 3rem;
    margin-top: 4.5rem;
  }
}

.cityName {
  margin-bottom: 2.5rem;
  h1 {
    color: $primary-beige;
    align-items: center;
    display: flex;
    @include header-xl;
    font-size: 4.5rem;
    margin: 0;
    margin-top: -1rem;

    @media (max-width: 960px) {
      align-items: flex-start;
      font-size: 3.5rem;
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      width: fit-content;
    }
  }
}

.cityIcon {
  width: 11rem;
  height: 12rem;
  margin-top: -2rem;
  margin-left: -1rem;
  margin-bottom: -2rem;

  @media (max-width: 960px) {
    width: 8rem;
    height: 9rem;
    margin-top: -1rem;
    margin-left: -1rem;
    margin-bottom: -1rem;
  }
}

.cityHeroLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.cityHeroRight {
  width: 50%;
  min-width: 632px;

  @media (max-width: 960px) {
    width: 100%;
    min-width: unset;
  }

  a {
    text-decoration: none;
  }

  h2 {
    @include header-m;
    color: $primary-beige;
    margin: 0;

    @media (max-width: 960px) {
      @include header-xs;
    }
  }
}

.cityHeroEventList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;

  .heroCard {
    border: 0.5px solid $neutral-white-alpha-15;
    cursor: pointer;
    min-width: 12.5rem;
    width: calc(33% - 1rem);
    height: 12.5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 960px) {
      width: calc(50% - 0.25rem);
      margin-bottom: 0.5rem;
      min-width: unset;
    }

    .heroCardImage {
      border-radius: 1rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
    .cardBg {
      border-radius: 1rem;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 6.1%,
        rgba(0, 0, 0, 0.25) 50.62%,
        rgba(0, 0, 0, 0.3) 58.43%,
        rgba(0, 0, 0, 0.4) 68.98%,
        rgba(0, 0, 0, 0.5) 81.87%,
        rgba(0, 0, 0, 0.6) 93.19%,
        rgba(0, 0, 0, 0.7) 105.69%,
        rgba(0, 0, 0, 0.8) 117.46%,
        #000 124.84%
      );
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    .cityCardInfo {
      .date {
        border-radius: 0.5rem;
        background: $neutral-black-alpha-30;
        backdrop-filter: blur(5px);
        color: $white;
        @include body-s-bold;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        padding: 0.25rem 0.5rem;
        text-transform: uppercase;
      }

      .title {
        color: $primary-beige;
        @include header-xxs;
        position: absolute;
        bottom: 1.25rem;
        left: 1rem;
        z-index: 100;

        @media (max-width: 960px) {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.heroMoreEvents {
  color: $primary-beige;
  border-radius: 1rem;
  border: 0.5px solid $neutral-white-alpha-15;
  background: $neutral-black-alpha-50;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  @include header-xs;
  text-transform: uppercase;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  text-decoration: none;
  width: 100%;
  img {
    margin-left: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }
}

.arrowUpRight {
  width: 1.5rem;
  height: 1.5rem;
  margin-left:12px;

  @media (max-width: 960px) {
    width: 1.125rem;
    height: 1.125rem;
  }
}

.citySportsSection {
  a {
    text-decoration: none;
  }
  h2 {
    color: $primary-beige;
    @include header-m;

    @media (max-width: 960px) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      text-wrap: pretty;
      @include header-xs;
    }
  }
  padding: 0 3.75rem;

  @media (max-width: 960px) {
    padding: 0 1.5rem;
  }

  .primarySport {
    width: calc(25% - (4.5rem / 4));
    img {
      border-radius: 1rem;
      width: 100%;
    }

    @media (max-width: 960px) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .sportsRow {
    display: flex;
    justify-content: space-between;

    @media (max-width: 960px) {
      flex-direction: column;
    }
  }
}

.otherSports {
  display: flex;
  flex-direction: column;
  width: calc(25% - (4.5rem / 4));

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 1rem;
  }

  .secondarySport {
    align-items: center;
    border-radius: 1rem;
    border: 0.5px solid $neutral-white-alpha-15;
    background: $neutral-black-alpha-50;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
      0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    color: $primary-beige;
    display: flex;
    @include header-xxs;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1.5rem 1rem;
    text-decoration: none;

    svg {
      margin-left: 0.5rem;
      height: 2rem;
      width: 2rem;
    }

    img {
      margin-left: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.citySection {
  padding: 2.5rem 3.75rem;

  @media (max-width: 960px) {
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
  }
  a {
    text-decoration: none;
  }
  h2 {
    color: $primary-beige;
    @include header-m;

    @media (max-width: 960px) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      @include header-xs;
      text-wrap: pretty;
    }
  }
}

.citySectionList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.card4w {
  width: calc((100% - 4.5rem) / 4);

  > div {
    width: 100%;
  }

  @media (max-width: 960px) {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}
