@import '../../css/global.scss';

.smallRebateContainer {
  cursor:pointer;
  box-sizing:border-box;
  display: flex;
  align-items:center;
  justify-content:space-between;
  width: 392px;
  padding: 0px 16px 0px var(--spacing-24, 24px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  border-radius: var(--spacing-8, 8px);
  border: 1px solid var(--brands-usdc, #2775CA);
  background: rgba(39, 117, 202, 0.30);
  /* Glass/Glass + Shadow */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);

  img {
    width: 124px;
    height: 125px;
  }

}

.smallRebateContainerLeft {
  display:flex;
  align-items:flex-start;
  flex-direction:column;
  justify-content:space-between;
}

.smallRebateContainerLeftTopText {
  color: var(--neutral-white, #FAFAFA);
  /* Body/Regular/S */
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom:16px;
}

.smallRebateContainerLeftBottomText {
  color: var(--neutral-white, #FAFAFA);
  font-family: Roboto Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}
