@import "../css/global.scss";

.nftListItem {
  background-color: rgba(250, 250, 250, 0.05);
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  width: 100%;
}

.banner {
  border-radius: 0.75rem 0.75rem 0 0;
  backdrop-filter: blur(5px);
  @include header-buttons;
  padding: 0.5rem;
  text-transform: uppercase;
}

.actionRequired {
  background-color: $system-yellow-alpha-15;
  color: $system-yellow-yellow;
}

.transferred {
  background-color: $system-green-alpha-15;
  color: $system-green-green;
}

.nftImageContainer {
  position: relative;
}

.nftImage {
  border-radius: 0 0 0 0.75rem;
  object-fit: cover;
  margin-left: 0.5px;
  width: 7.5rem;
  height: 7.5rem;

  @media (max-width: 960px) {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
  }
}

.nftInfo {
  align-items: center;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.nftDate {
  color: $neutral-white-alpha-50;
  display: flex;
  flex-direction: column;
  @include body-xs-20;
  min-width: 7rem;
  text-align: right;

  @media (max-width: 960px) {
    text-align: left;
  }
}

.monthDate {
  color: $white;
  @include header-xxs;
  margin-bottom: 0.5rem;
}

.nftTitle {
  color: $primary-beige;
  @include header-xxs;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.nftVenue {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s-light;
  svg {
    margin-right: 0.5rem;
    path {
      fill: $secondary-gray-white;
    }
  }
}

.ticketQuantity {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: fit-content;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  svg {
    margin-right: 0.5rem;
  }
}

.nftStatus {
  align-items: center;
  display: flex;

  @media (max-width: 960px) {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.metadata {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  width: calc(100% - 3rem);

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 1.5rem 1rem;
    width: calc(100% - 2rem);
  }
}

.titleContainer {
  padding-bottom: 1.5rem;
  border-bottom: 0.5px solid $neutral-white-alpha-15;
}

.rightContent {
  align-items: center;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 1.5rem;
    width: 100%;
  }
}

.ticketQuantity {
  border-radius: 0.25rem;
  background-color: rgba(250, 250, 250, 0.05);
  color: $white;
  font-family: Sequel100Black-65;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 5rem;
  padding: 0.25rem 0.5rem;
  width: fit-content;
}
