@import "../css/global.scss";

.container {
  background: $brand-dark-blue;
  border: 0.5px solid $brand-blue;
  box-shadow: 0px 8px 100px 0px rgba(133, 213, 255, 0.4);
  border-radius: 1rem;
  padding: 2rem;

  @media (max-width: 960px) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    height: calc(100vh - 10rem);
    margin-top: 6.125rem;
    margin-left: -1rem;
    overflow: hidden;
    width: calc(100% - 2rem);
  }
}

.modalContainer {
  @media (max-width: 960px) {
    overflow: hidden;
  }
}

.close {
  cursor: pointer;
  display: block;
  margin-left: auto;
  z-index: 1;
}

.icon {
  font-size: 40px;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
}

.header {
  color: $primary-beige;
  text-shadow: 0px 0px 100px rgba(133, 213, 255, 0.5);
  font-family: Sequel100Black-75;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 2.25rem */
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.infoContainer {
  color: $white;
  background: $brand-darker-blue;
  border: 0.5px solid $brand-blue-alpha-15;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 1rem;
  width: calc(100% - 2rem);
}

.listItem {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    text-transform: uppercase;
    width: calc(50% - 0.5rem);
  }
}
