@import "../../css/global.scss";

.tile {
  @include stroke-border;
  @include glass-background;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 8rem;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: calc(25% - 3rem);
  padding: 1rem;

  &:hover {
    background: $glass-stroke;
    @include stroke-border-m;
    transition: 0.1s all;
  }

  @media (max-width: 768px) {
    height: 9rem;
    width: calc(50% - 2.5rem);
  }
}

.tileLabel {
  display: flex;
  flex-direction: column;
}

.subtitle {
  border-bottom: 1px solid $primary-beige;
  color: $primary-beige;
  @include header-xs;
  font-size: 16px;
  padding-bottom: 0.2rem;
  text-shadow: 0px 0px 50px rgba(67, 186, 125, 0.5);
  text-transform: uppercase;
  width: fit-content;
}

.number {
  color: $primary-beige;
  @include body-s-bold;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-shadow: 0px 0px 50px rgba(67, 186, 125, 0.5);
  text-transform: uppercase;
}

.arrow {
  align-self: flex-end;
  height: 1.3125rem;
  width: 1.3125rem;
}
