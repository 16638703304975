@import "../../css/global.scss";

.homeDiscoveryInner {
  width: 100%;
}

.homeDiscoveryRow {
  margin: 0 auto;
  padding: 2rem 0 2rem 1.5rem;

  @media (max-width: 960px) {
    padding: 2rem 0 0.5rem 0;
    width: 100%;
  }
}

.discoverySectionHeader {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: 960px) {
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    color: $primary-beige;
    @include header-m;
    margin: 0;
    text-transform: uppercase;

    @media (max-width: 960px) {
      @include header-xs;
    }
  }

  button {
    margin-left: 1.5rem;
    z-index: 0;

    @media (max-width: 960px) {
      margin-left: 0;

      svg {
        margin-left: -0.5rem;
      }
    }
  }
}

.discoverySectionList {
  display: flex;
  justify-content: space-between;
  overflow: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: 960px) {
    width: calc(100% + 1rem);
  }

  & > div {
    margin-right: 1.5rem;

    @media (max-width: 960px) {
      width: 18rem;
      & > div {
        width: 18rem;
      }
    }
  }
}

.locationList {
  flex-direction: row;

  .card4w {
    width: calc((100% - 4.5rem) / 4);
    @media (max-width: 960px) {
      width: calc(50% - 0.5rem);
    }
  }
}

.card2w {
  width: calc((100% - 2rem) / 2);

  @media (max-width: 960px) {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.card4w {
  @media (max-width: 960px) {
    width: 18rem;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.button {
  border: 1px solid $brand-blue;
  height: unset;
  font-family: Roboto Mono;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1625rem;
  min-width: fit-content;
  padding: 0.75rem 1rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}
