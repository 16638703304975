@import "../css//global.scss";

.rewardInner {
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
}

.rewardContainer {
  padding: 1.5rem;
  margin-left: 1.5rem;
  width: 46rem;

    @media (max-width: 960px) {
        margin-left: 0;
        padding: 0 1rem;
        width: 100%;
    }

  h1 {
    color: $primary-beige;
    @include header-xl;
    font-size: 1.5625rem;
  }
}

.rewardCard {
  border-radius: 0.75rem;
  background-color: rgba(250, 250, 250, 0.05);
  display: flex;
  margin-bottom: 1rem;
  img {
    border-radius: 0.75rem 0 0 0.75rem;
    object-fit: cover;
    object-position: center;
    width: 7.5rem;
    height: 7.5rem;
  }

  .rewardCardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;

    h3 {
      color: $primary-beige;
      font-family: Sequel100Black-75;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 1rem */
      margin: 0;
      margin-bottom: 0.25rem;
      text-transform: uppercase;
    }

    p {
      color: $system-green-green;
      font-family: "Roboto Mono";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
      letter-spacing: 0.04063rem;
      text-transform: uppercase;
    }
  }
}

.noRewards {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem;
    text-align: center;

    @media (max-width: 960px) {
        padding: 5rem 3rem;
    }

    p {
        color: $primary-beige;
        @include body-m-light;
    }
}

.loading {
  width: 3rem;
  height: 3rem;
}